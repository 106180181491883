import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from "@angular/router";
import {AppStateService} from "../state/app-state.service";
import {AuthService} from "./auth.service";
import {Observable} from "rxjs";
import {HevreRouterService} from "../router/hevre-router.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private state: AppStateService, private hvrAuth: AuthService,
              private hvrRouter: HevreRouterService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.state.getUser()) {
      return Promise.resolve(true);
    } else {
      console.log('Auth Guard Refuse...');
      this.hvrRouter.navigate(['login']);
    }
  }
}
