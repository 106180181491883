import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import {MatRadioModule} from '@angular/material/radio';
import {BrowserAnimationsModule, NoopAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { environment } from '../environments/environment';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import {MatMenuModule} from '@angular/material/menu';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TableComponent } from './app-reusable-components/table/table.component';
import {CdkTableModule} from "@angular/cdk/table";
import { FileSelectorComponent } from './app-reusable-components/file-selector/file-selector.component';
import {NgxSpinnerModule} from "ngx-spinner";
import { TeamDialogComponent } from './dashboard/dialogs/team-dialog/team-dialog.component';
import { TeamCardComponent } from './dashboard/team-card/team-card.component';
import { PersonCardComponent } from './dashboard/person-card/person-card.component';
import { MessageDialogComponent } from './app-reusable-components/message-dialog/message-dialog.component';
import {RouterModule} from "@angular/router";
import {APP_ROUTES} from "./app.routes";
import { NotFoundComponent } from './not-found/not-found.component';
import { TeamViewComponent } from './team-view/team-view.component';
import { MenuComponent } from './menu/menu.component';
import { ManageTeamsComponent } from './manage-teams/manage-teams.component';
import { AddressCardsComponent } from './address-cards/address-cards.component';
import { ManageAddressesComponent } from './manage-addresses/manage-addresses.component';
import { PieComponent } from './app-reusable-components/charts/pie/pie.component';
import {ChartModule} from 'primeng/chart';
import {HttpClientModule} from "@angular/common/http";
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { FilterDropdownComponent } from './app-reusable-components/filter-dropdown/filter-dropdown.component';
import { TeamBulkDialogComponent } from './dashboard/dialogs/team-bulk-dialog/team-bulk-dialog.component';
import { SelectTeamDialogComponent } from './manage-addresses/dialogs/select-team-dialog/select-team-dialog.component';
import {TableModule} from "primeng/table";
import { BarComponent } from './app-reusable-components/charts/bar/bar.component';
import { DeliverySummeryDialogComponent } from './team-view/dialogs/delivery-summery-dialog/delivery-summery-dialog.component';


@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    DashboardComponent,
    TableComponent,
    FileSelectorComponent,
    TeamDialogComponent,
    TeamCardComponent,
    PersonCardComponent,
    MessageDialogComponent,
    NotFoundComponent,
    TeamViewComponent,
    MenuComponent,
    ManageTeamsComponent,
    AddressCardsComponent,
    ManageAddressesComponent,
    PieComponent,
    FilterDropdownComponent,
    TeamBulkDialogComponent,
    SelectTeamDialogComponent,
    BarComponent,
    DeliverySummeryDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    MatTableModule,
    CdkTableModule,
    MatPaginatorModule,
    NgxSpinnerModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    MatSelectModule,
    AngularFirestoreModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatMenuModule,
    ChartModule,
    TableModule,
    HttpClientModule,
    MatAutocompleteModule,
    RouterModule.forRoot(APP_ROUTES),
  ],
  entryComponents: [
    TeamDialogComponent,
    MessageDialogComponent,
    TeamBulkDialogComponent,
    SelectTeamDialogComponent,
    DeliverySummeryDialogComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
