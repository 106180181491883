import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Person, Team} from "../dashboard/dialogs/team-dialog/interfaces/team.type";
import {DaoService} from "../app-dao/dao.service";
import {NgxSpinnerService} from "ngx-spinner";
import {FileReaderService} from "../app-reusable-components/file-selector/services/file-reader/file-reader.service";
import {ReadXlsService} from "../app-reusable-components/file-selector/services/read-xls/read-xls.service";
import {AUTH_ERROR_CODES, AuthService} from "../app-services/auth/auth.service";
import {TeamUtilsService} from "../dashboard/dialogs/team-dialog/services/team-utils.service";
import * as _ from "lodash";
import {TeamDialogComponent} from "../dashboard/dialogs/team-dialog/team-dialog.component";
import {AddressRecord} from "../dashboard/address-record.type";
import {AddressesService} from "../app-services/addresses/addresses.service";
import {Subscription} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {take} from "rxjs/operators";
import {CloudFunctionsService} from "../cloud-functions/cloud-functions.service";
import {TeamChange} from "./team-change.type";
import {TeamBulkDialogComponent} from "../dashboard/dialogs/team-bulk-dialog/team-bulk-dialog.component";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'hvr-manage-teams',
  templateUrl: './manage-teams.component.html',
  styleUrls: ['./manage-teams.component.less']
})
export class ManageTeamsComponent implements OnInit, OnDestroy {

  teams: Team[] = [];
  errorMessage: string;
  failedDrivers = [];
  availableAddressesGroups = [];
  availableAddressesGroupsSubscription: Subscription;
  addressesData: AddressRecord[] = [];
  addressesSubsciption: Subscription;


  constructor(private teamsDao: DaoService<Team>,
              private spinner: NgxSpinnerService,
              private fileReaderService: FileReaderService,
              private readXlsService: ReadXlsService,
              private authSrv: AuthService,
              private teamUtils: TeamUtilsService,
              private dialog: MatDialog,
              private addressesSrv: AddressesService,
              private cloudFunctionsSrv: CloudFunctionsService,
              private teamsUtils: TeamUtilsService,
              private addressesDao: DaoService<AddressRecord>,
              private route: ActivatedRoute,
              private usersDao: DaoService<Person>
  ) {
  }

  ngOnInit() {
    this.readTeamsFromServer();

    this.availableAddressesGroupsSubscription = this.addressesSrv.availableAddressesGroups$.subscribe((value: []) => {
      this.availableAddressesGroups = value;
      console.log('availableAddressesGroups: ', this.availableAddressesGroups);
    });

    this.addressesSubsciption = this.addressesSrv.addressesOnDB$.subscribe((value: AddressRecord[]) => {
      this.addressesData = value;
    });
  }

  ngOnDestroy(): void {
    this.addressesSubsciption.unsubscribe();
    this.availableAddressesGroupsSubscription.unsubscribe();
  }

  filterTeamByParam() {
    const teamByParam = this.route.snapshot.queryParams['team'];
    if (teamByParam) {
      this.searchTeam({'target': {'value': teamByParam}});
    }
  }

  private readTeamsFromServer() {
    console.log('retrieving teams...');
    this.spinner.show();
    this.teamsDao.read('teams').subscribe((data) => {
      this.teams = data;
      this.filterTeamByParam();
      this.spinner.hide();
    })
  }

  handleAddTeam() {
    this.openAddTeamDialog();
  }

  openAddTeamDialog() {
    const dialogRef = this.dialog.open(TeamDialogComponent, {
      width: '75%',
      data: {
        groups: this.availableAddressesGroups,
      }
    });

    dialogRef.afterClosed().subscribe(dialogResults => {

      if (!dialogResults) {
        return;
      }

      let teamReturened = dialogResults.team;

      const assignAmount = dialogResults.hasOwnProperty('meta') &&
      dialogResults.meta.hasOwnProperty('requestedAddressesAmount') ?
        dialogResults.meta.requestedAddressesAmount : 0;

      const assignFromGroup = dialogResults.hasOwnProperty('meta') &&
      dialogResults.meta.hasOwnProperty('assignFromGroup') ? dialogResults.meta.assignFromGroup : null;

      if (teamReturened) {
        console.log('is Dirty: ', teamReturened.isDirty);

        this.addTeam(teamReturened, assignAmount, assignFromGroup);
      }

    })
  }

  handleBulkTeamsCreation() {
    this.openBulkTeams();
  }

  openBulkTeams() {
    const dialogRef = this.dialog.open(TeamBulkDialogComponent, {
      width: '75%',
      data: {
        addressGroups: this.availableAddressesGroups,
        dbTeams: this.teams
      }
    });

    dialogRef.afterClosed().subscribe(dialogResults => {

      if (!dialogResults) {
        return;
      }

    })
  }

  async addTeam(team: Team, assignAmount: number = 0, assignFromGroup: string = null): Promise<number> {
    // this.spinner.show();
    if (team) {
      try {
        const driverPass = team.driver.phone.replace(/\+972/, '0');
        await this.authSrv.createUser(team.driver.email, driverPass, team.driver);
        this.createTeam(team);
        this.setAddressesForTeam(team, assignAmount, assignFromGroup);
      } catch (e) {
        if (e.code == AUTH_ERROR_CODES.emailInUse) {
          if (_.find(this.teams, (obj) => obj.driver.email == team.driver.email)) {
            console.log('Driver cant have more then one team');
            // this.spinner.hide();
            return Promise.reject(this.teams.length);
          } else {
            // this is a valid case that we already has a user record in the DB for the driver but he has no team in the DB
            this.createTeam(team); // we allow two teams for the same driver
            this.setAddressesForTeam(team, assignAmount, assignFromGroup);
            // this.spinner.hide();
            return Promise.resolve(this.teams.length);
          }
        }
        console.log('Error in adding new team', e);
        if (this.teams.indexOf(team) != -1) {
          this.teams.splice(this.teams.indexOf(team), 1);
          this.teams = _.sortBy(this.teams, team => team.name);
        }
        // this.spinner.hide();
        return Promise.reject(this.teams.length);
      }
    }
    // this.spinner.hide();
    return Promise.resolve(this.teams.length);
  }

  private createTeam(team: Team) {
    this.teams.push(team);
    this.teamsDao.create('teams', this.teams);
    this.teams = _.sortBy(this.teams, team => team.name);
  }

  handleTeamEdited(teamChange: TeamChange) {
    console.log('teamChange: ', teamChange);

    console.log('is dirty:', teamChange['teamAfter'].isDirty);
    if (!teamChange['teamAfter'].isDirty) {
      console.log('no change made for the team');
      return;

    }

    const {teamBefore, teamAfter, assignAmount, assignFromGroup} = teamChange;
    // if (_.isEqual(teamBefore, teamAfter)) {
    //   return;
    // }


    //TODO: performance this operation takes 9 sec when removing a address group from the team, only 1 sec when adding address group to the team
    this.teamsDao.update('teams', [teamAfter], teamAfter.id).then(
      success => {
        console.log('team successfully updated');
        this.setAddressesForTeam(teamAfter, assignAmount, assignFromGroup);
      }
    ).catch(
      error => {
        console.log(`fail to update team: ${error}`);
        // this.spinner.hide();
      }
    ).finally(() => this.recordTimestamp('handleTeamEdited end')
    );

    this.usersDao.update('users', [teamAfter.driver], teamAfter.driver.id).then(
      success => {
        console.log('driver details successfully updated');
      }
    );
  }

  async handleTeamDeleted(deletedTeam: Team) {
    if (!deletedTeam) {
      console.log('Error: no team to delete!');
      this.spinner.hide();
      return;
    }

    this.cloudFunctionsSrv.deleteUserFromAuth(deletedTeam.driver.email).toPromise().then((response) => {
          console.log(response);
      }
    ).catch((error) => {
      console.log('Error while deleting from auth: ', error);
    });

    let teamAddresses = await this.teamsUtils.getAddressesOfTeam(deletedTeam);
    _.forEach(teamAddresses, address => address.team = null);
    if (teamAddresses) {
      console.log('Free the team addresses...')
      this.addressesDao.update('addresses', teamAddresses).then(success => {
          console.log('Now Deleting the team...');
          this.teamsDao.delete('teams', deletedTeam.id).finally(() => this.spinner.hide());
          this.usersDao.delete('users', deletedTeam.driver.id);
        }
      ).catch(err => {
          console.log('Unable to delete team addresses, Abort team deletion... error: ', err);
        }
      );
    }
  }

  async exportTeams() {
    this.spinner.show();
    await this.teamUtils.exportTeams();
    this.spinner.hide();
  }

  deleteAllTeams() {
    if (confirm('Are you sure you want to delete all teams from the database?')) {
      _.forEach(this.teams, team => this.handleTeamDeleted(team).then(
        success => console.log('done delete for team: ', team.name)));
      console.log('All Teams Deleted from the database.');
    } else {
      // Do nothing!
      console.log('User Canceled Operation.');
    }
  }

  removeTeam(team: Team): number {
    if (team) {
      this.teams = _.sortBy(_.remove(this.teams, (t) => t.id == team.id), team => team.name);
    }
    return this.teams.length;
  }

  searchTeam(event) {
    let searchTerm = event.target.value;
    if (searchTerm && searchTerm != "") {
      const exactTeam: Team = _.find(this.teams, (i) => i.id == searchTerm);
      if (exactTeam) {
        this.teams = [exactTeam];
        return;
      }

      const filteredTeams: Team[] = _.filter(this.teams, (team) => {
        return _.startsWith(team.driver.phone, searchTerm) ||
          !_.isNil(_.find(_.words(team.name), (i) => _.startsWith(i.toLowerCase(), searchTerm.toLowerCase())))
      })

      if (filteredTeams && filteredTeams.length > 0) {
        this.teams = filteredTeams;
      }

    } else {
      this.spinner.show();
      this.teamsDao.read('teams').subscribe((data) => {
        this.teams = _.sortBy(data, team => team.name);
        this.spinner.hide();
      })
    }
  }


  recordTimestamp(name: string = '') { //TODO move to utils
    let date = new Date();
    console.log(name, date.getMinutes() + ':' + date.getSeconds());
  }

  setAddressesForTeam(team: Team, howManyAddresses: number, onlyFromSpecificGroup: string = null) {
    if (!howManyAddresses || howManyAddresses === 0) {
      console.log('Invalid Amount to assign!');
      return;
    }

    this.cloudFunctionsSrv.assignAddressesForTeam(team.id, howManyAddresses, onlyFromSpecificGroup).pipe(take(1)).toPromise()
      .then((response) => {
        console.log('response is: ', response);


      }).catch((error) => {
      console.log('error in set addresses for team: ', error)

    });
  }


}
