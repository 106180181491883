import {Component, Input, OnInit} from '@angular/core';
import {SlicesConfig} from "./interfaces/slicesConfig";

@Component({
  selector: 'hvr-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.less']
})
export class PieComponent implements OnInit {
  data: any;
  @Input() slicesConfig: SlicesConfig;

  constructor() {
  }

  ngOnInit() {

    this.data = {
      labels: this.slicesConfig.labels,
      datasets: [
        {
          data: this.slicesConfig.initialValues,
          backgroundColor: this.slicesConfig.slicesColors,
          hoverBackgroundColor: this.slicesConfig.slicesHoverColors,
        }]
    };
  }


}
