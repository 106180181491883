import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  constructor() {
  }

  timeStampToTimeFormat(stamp): string {
    if (!stamp) {
      return "";
    }
    let date = new Date(stamp);

    return date.toDateString() + "-" + date.toLocaleTimeString();

  }

  sleep(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
  }
}
