export const xlsxFileValidator = (files) => {
    if (files && files.length > 0) {
        if (files[0].type && files[0].type != "") {
            return files[0].type == 'text/xls' || files[0].type.includes(".sheet");
        }
        else {
            if (files[0].name && files[0].name != "") {
                return files[0].name.includes(".xls") || files[0].name.includes(".xlsx")
            }
        }
    }
    else {
        return false;
    }
};
