<h1>
  <p>{{team.name}}</p>
</h1>

<p class="error" *ngIf="errorMsg">{{errorMsg}}</p>

<div mat-dialog-content>
  <div id="driver">
    <h3>Driver Details:</h3>
    <div class="section">
      <label for="driver-name">Driver Name:</label>
      <input *ngIf="!driverConfirmed" id="driver-name" class="input" matInput [(ngModel)]="tempDriver.name">
      <p *ngIf="driverConfirmed">{{team.driver.name}}</p>
    </div>
    <div class="section">
      <label for="driver-email">Driver Email:</label>
      <input *ngIf="!driverConfirmed" id="driver-email" class="input" matInput [(ngModel)]="tempDriver.email">
      <p *ngIf="driverConfirmed">{{team.driver.email}}</p>
    </div>
    <div class="section">
      <label for="driver-phone">Driver Phone:</label>
      <input *ngIf="!driverConfirmed" id="driver-phone" class="input" matInput [(ngModel)]="tempDriver.phone">
      <p *ngIf="driverConfirmed">{{team.driver.phone}}</p>
    </div>
    <div class="section">
      <button *ngIf="!driverConfirmed" id="confirm-driver" mat-raised-button color="primary"
              (click)="handleConfirmDriver()">Confirm Driver
      </button>

      <button *ngIf="driverConfirmed" id="edit-driver" mat-raised-button color="primary" (click)="handleEditDriver()">
        Edit Driver
      </button>
    </div>
  </div>

  <div id="team-operations">
    <div class="section">
      <button id="add-member" mat-raised-button color="primary" (click)="showMemberForm()">Add Member
      </button>
    </div>
  </div>

  <a *ngIf="team.members.length > 0 && !displayMembers" (click)="showMembers()" id="members-count"
     class="link">Show {{team.members.length}} Members.</a>

  <a *ngIf="displayMembers" (click)="hideMembers()" id="members-count-hide" class="link">Hide {{team.members.length}}
    Members.</a>

  <div id="members" *ngIf="displayMembers">
    <div *ngFor="let member of team.members">
      <div class="show-members-section" *ngIf="member?.meta['confirmed']">
        <span><b>name:</b> {{member?.name}} </span>
        <span><b>email:</b> {{member?.email}} </span>
        <span><b>phone:</b> {{member?.phone}} </span>
        <i id="edit-btn" class="material-icons md-12" (click)="member.meta['confirmed']=false">edit</i>
      </div>

      <div class="edit-members-section" *ngIf="!member?.meta['confirmed']">
        <span><b>name:</b> <input class="input" matInput [(ngModel)]="member.name"> </span>
        <span><b>email:</b> <input class="input" matInput [(ngModel)]="member.email"> </span>
        <span><b>phone:</b> <input class="input" matInput [(ngModel)]="member.phone"></span>
        <button style="margin-top:1rem" mat-raised-button color="primary" (click)="member.meta['confirmed']=true">
          Confirm Member
        </button>
      </div>
    </div>
  </div>

  <div id="members-form" *ngIf="memberFormVisible">
    <h3>Member Details:</h3>
    <div class="section">
      <label for="member-name">Member Name:</label>
      <input id="member-name" class="input" matInput [(ngModel)]="tempMember.name">
    </div>
    <div class="section">
      <label for="member-email">Member Email:</label>
      <input id="member-email" class="input" matInput [(ngModel)]="tempMember.email">
    </div>
    <div class="section">
      <label for="member-phone">Member Phone:</label>
      <input id="member-phone" class="input" matInput [(ngModel)]="tempMember.phone">
    </div>

    <div class="section">
      <button id="confirm-member" mat-raised-button color="primary" (click)="handleAddMember()">Confirm Member
      </button>
    </div>
  </div>

  <div id="manage-team-addresses">
    <h3>Manage Addresses For The Team:</h3>

    <div id="addresses-summary">This team will deliver for <a
      (click)="showAddressesList = !showAddressesList">{{addressesAssignedAmount}}</a> addresses
    </div>
    <br/>
    <div id="team-addresses-list" *ngIf="showAddressesList">
      <div class="address-record" *ngFor="let address of teamAddresses">
        <span>{{getAddressDisplay(address)}}</span>
        <span>
          <button type="button" class="close" aria-label="Close" (click)="removeAddress(address)">
            <span aria-hidden="true">&times;</span>
          </button>
        </span>
      </div>
    </div>


    <h4>Add more Addresses To The Team:</h4>
      <mat-form-field>
        <mat-label>Assign More Addresses</mat-label>
        <input matInput placeholder="How Many?"
               [(ngModel)]="requestedAddressesAmount">
      </mat-form-field>
    &nbsp;
    <mat-form-field>
      <mat-label>Assign Only From Specific Group?</mat-label>
      <mat-select [(ngModel)]="assignFromGroup">
        <!--<mat-option value="{{null}}">None</mat-option>-->
        <mat-option *ngFor="let group of data.groups" value="{{group}}">{{group}}</mat-option>
      </mat-select>
    </mat-form-field>


  </div>

  <div id="dialog-footer">
    <!--<button id="create-team-btn" mat-raised-button color="accent"-->
    <!--[ngClass]="{'disable': !canConfirmTeam()}" (click)="confirmTeam()">Confirm-->
    <!--</button>-->

    <button id="create-team-btn" class="btn btn-primary" type="button"
            [ngClass]="{'disabled': !canConfirmTeam() || processing}" (click)="confirmTeam()">
      <span *ngIf="!processing">Confirm</span>
      <span *ngIf="processing">Processing... </span>
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="processing"></span>

    </button>
  </div>

</div>
