import {Routes} from "@angular/router";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {AuthComponent} from "./auth/auth.component";
import {NotFoundComponent} from "./not-found/not-found.component";
import {TeamViewComponent} from "./team-view/team-view.component";
import {AuthGuardService} from "./app-services/auth/auth-guard.service";
import {RoleGuardService} from "./app-services/guards/role-guard.service";
import {UserRole} from "./types/userRoles.enum";
import {ManageTeamsComponent} from "./manage-teams/manage-teams.component";
import {ManageAddressesComponent} from "./manage-addresses/manage-addresses.component";

export const APP_ROUTES: Routes = [
  {
    path: 'login',
    children: [
      {path: '', component: AuthComponent},
      {path: '**', component: NotFoundComponent},
    ],
  },

  {
    path: '',
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [RoleGuardService],
        data: {roles: [UserRole.Admin]}
      },
      {
        path: 'manage-addresses',
        component: ManageAddressesComponent,
        canActivate: [RoleGuardService],
        data: {roles: [UserRole.Admin]}
      },

      {
        path: 'manage-teams', component: ManageTeamsComponent, canActivate: [RoleGuardService],
        data: {roles: [UserRole.Admin]},
        children: [
          {
            'path': ':teamName',
            component: ManageTeamsComponent
          }

        ]
      },

      {path: 'team-view', component: TeamViewComponent},
      {path: '', component: DashboardComponent},
      {path: '**', component: NotFoundComponent},
    ],
  }
];
