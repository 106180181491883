import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
// import {environment} from "../../environments/environment.prod";

export interface AngularHttpClientRequestOptions {
  body?: any;
  headers?: HttpHeaders;
  observe?: any;
}

export interface HttpRequestOptions {
  method?: HttpMethod;
  data?: any;
  headers?: { [key: string]: string };
}

export enum HttpMethod {
  GET,
  POST,
  DELETE,
  PUT,
  HEAD,
  PATCH,
  OPTIONS
}

export interface TeamsAmountsToServer {
  teamId: string,
  amount: number
}

const methodToAngularMethod: { [key: string]: string; } = {};
methodToAngularMethod[HttpMethod.GET] = 'GET';
methodToAngularMethod[HttpMethod.POST] = 'POST';
methodToAngularMethod[HttpMethod.PATCH] = 'PATCH';
methodToAngularMethod[HttpMethod.PUT] = 'PUT';
methodToAngularMethod[HttpMethod.HEAD] = 'HEAD';
methodToAngularMethod[HttpMethod.OPTIONS] = 'OPTIONS';
methodToAngularMethod[HttpMethod.DELETE] = 'DELETE';

@Injectable({
  providedIn: 'root'
})
export class CloudFunctionsService {

  private baseUrl: string = environment.cloudFunctionsBaseUrl;

  constructor(private httpClient: HttpClient) {
  }

  assignAddressesForTeam(teamId: string, amount: number, onlyFromSpecificGroup: string): Observable<any> {
    if (!teamId || !amount) {
      console.log(`Cant assign addresses for teamId: ${teamId} with amount: ${amount}`);
      return new Observable<any>(null);
    }
    const url = this.baseUrl + '/teams/set-team-addresses';
    const data = {

      'teamId': teamId,
      'amount': amount.toString(),
      'group': onlyFromSpecificGroup
    };
    return this.post(url, data);
  }

  deleteUserFromAuth(userEmail: string): Observable<any> {
    const url = this.baseUrl + '/teams/delete-user-from-auth';
    const data = {
      'userEmail': userEmail,
    };
    return this.post(url, data);
  }

  assignAddressesForTeams(teamsAmounts: TeamsAmountsToServer[], onlyFromSpecificGroup?: string): Observable<any> {
    if (!teamsAmounts || teamsAmounts.length === 0) {
      console.log(`Invalid teams:`);
      return new Observable<any>(null);
    }
    const url = this.baseUrl + '/teams/set-teams-addresses';
    const data = {
      'group': onlyFromSpecificGroup,
      'teamsAmounts': teamsAmounts
    };
    const httpOptions = {
      data: data,
      headers: {'Content-Type': 'application/json'}
    };
    return this.post(url, null, httpOptions);
  }

  private get<T>(url: string, options?: HttpRequestOptions): Observable<any> {
    return this.request<T>(url, {method: HttpMethod.GET, ...options});
  }

  private post(
    url: string,
    data?: any,
    options?: HttpRequestOptions
  ): Observable<any> {
    return this.request(url, {method: HttpMethod.POST, data, ...options});
  }

  private put(
    url: string,
    data?: any,
    options?: HttpRequestOptions
  ): Observable<any> {
    return this.request(url, {method: HttpMethod.POST, data, ...options});
  }

  private delete(url: string, options?: HttpRequestOptions): Observable<any> {
    return this.request(url, {method: HttpMethod.DELETE, ...options});
  }

  private request<T>(url: string, options?: HttpRequestOptions): Observable<T> {
    let {method, headers} = options;

    const angularHttpMethod = methodToAngularMethod[method];
    const angularRequestOptions: AngularHttpClientRequestOptions = {};

    if (options.data) {
      angularRequestOptions.body = options.data;
    }

    if (headers) {
      angularRequestOptions.headers = new HttpHeaders(headers);
    }

    return this.httpClient.request<T>(
      angularHttpMethod,
      url,
      angularRequestOptions
    );
  }
}
