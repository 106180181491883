import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  constructor() {
  }

  getSessionStorage() {
    return window.sessionStorage;
  }

  getLocaleStorage() {
    return window.localStorage;
  }

  getNativeWindow(): any {
    return window;
  }

  reloadWindow() {
    window.location.reload();
  }

  detectIEBrowser(ignoreEdge) {
    let ua = this.getNativeWindow().navigator.userAgent;
    let msie = ua.indexOf('MSIE ');
    let edge = ua.indexOf('Edge');
    if (ignoreEdge) {
      return msie > 0 || !!ua.match(/Trident.*rv\:11\./);
    } else {
      return msie > 0 || edge > 0 || !!ua.match(/Trident.*rv\:11\./);
    }
  }
}
