import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppStateService} from "../app-services/state/app-state.service";
import {FileReaderService} from "../app-reusable-components/file-selector/services/file-reader/file-reader.service";
import {ReadXlsService} from "../app-reusable-components/file-selector/services/read-xls/read-xls.service";
import {NgxSpinnerService} from "ngx-spinner";
import {MatDialog} from "@angular/material/dialog";
import {AddressRecord} from "../dashboard/address-record.type";
import {Team} from "../dashboard/dialogs/team-dialog/interfaces/team.type";
import {Subject, Subscription} from "rxjs";
import {MessageDialogComponent} from "../app-reusable-components/message-dialog/message-dialog.component";
import {FieldValidator} from "../app-reusable-components/file-selector/interfaces/fieldValidator";
import {FileValidator} from "../app-reusable-components/file-selector/interfaces/fileValidator";
import {xlsxFileValidator} from "../app-reusable-components/file-selector/validators/xlsxFileValidator";
import {AddressesService} from "../app-services/addresses/addresses.service";
import {ActivatedRoute} from "@angular/router";
import {AddressUtilsService} from "../app-services/addresses/address-utils.service";
import {ToolsService} from "../app-services/tools/tools.service";
import * as _ from "lodash";


@Component({
  selector: 'hvr-manage-addresses',
  templateUrl: './manage-addresses.component.html',
  styleUrls: ['./manage-addresses.component.less']
})
export class ManageAddressesComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['Id', 'Name', 'Address', 'City', 'Phone', 'Details', 'Group', 'Team', 'Status', 'Other',
    'last_update'];
  addressesData: AddressRecord[] = [];
  refresher: Subject<boolean>;
  filterTableByString: string;
  availableAddressesGroups = [];
  fieldsValidators: Array<FieldValidator> = [];
  errorMessage: string;
  fileValidators: FileValidator[] = [];
  addressesSubsciption: Subscription;
  availableAddressesGroupsSubscription: Subscription;


  constructor(private state: AppStateService,
              private fileReaderService: FileReaderService,
              private readXlsService: ReadXlsService,
              private spinner: NgxSpinnerService,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private addressesSrv: AddressesService,
              private AddressUtilsSrv: AddressUtilsService,
              private ToolsSrv: ToolsService) {
  }

  ngOnInit() {
    this.refresher = new Subject<boolean>();
    this.initFileValidators();
    this.subscribeAddresses();
  }

  subscribeAddresses(): void {
    this.addressesSubsciption = this.addressesSrv.addressesOnDB$.subscribe((value: AddressRecord[]) => {
      this.addressesData = value;
      this.filterAddressesByParam();
    });
    this.availableAddressesGroupsSubscription = this.addressesSrv.availableAddressesGroups$.subscribe((value: []) => {
      this.availableAddressesGroups = value;
    });
  }

  unsubscribeAddresses(): void {
    this.addressesSubsciption.unsubscribe();
    this.availableAddressesGroupsSubscription.unsubscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribeAddresses();
  }

  async handleDeleteAddresses() {
    if (confirm('Are you sure you want to delete all addresses from the database?')) {
      this.unsubscribeAddresses(); // we need to unsubscribe before changing the DB to prevent getting out of sync
      let addressesCount = this.addressesData.length;
      for (let i = 0; i < addressesCount; i++) {
        const address = this.addressesData[i];
        console.log(`now deleting address number: ${address.id.toString()}...`)
        this.AddressUtilsSrv.deleteAddressOnDB(address.id.toString());
        await this.ToolsSrv.sleep(2000);
      }
      this.subscribeAddresses();
      console.log('All Addresses Deleted from the database.');
    } else {
      // Do nothing!
      console.log('User Canceled Operation.');
    }
  }

  filterAddressesByParam() {
    const addressByParam = this.route.snapshot.queryParams['address'];
    if (addressByParam) {
      this.filterTableByString = addressByParam;
    }
  }

  initFileValidators() {
    const fileValid: FileValidator = {
      messageOnFailure: 'Only xls/xlsx files are allowed',
      validate: xlsxFileValidator
    };
    this.fileValidators.push(fileValid);
  }

  onAddressesChanged(event) {
    this.spinner.hide(); // table is done loading we can hide the spinner if exists
  }

  handleAddressesFileSelected(file) {
    const loadAddressesDialogRef = this.dialog.open(MessageDialogComponent, {
      width: '75%',
      data: {
        message: 'This operation will delete the previous addresses and all its data!',
        includePossitive: true,
        includeNegative: true,
        header: 'Are you sure?',
        possitiveText: 'Im Sure',
        negativeText: 'Cancel'
      }
    });

    loadAddressesDialogRef.afterClosed().subscribe(userConfirmation => {
      if (userConfirmation) {
        console.log('Loading Confirmed');
        this.spinner.show();
        try {
          // const addressesFromFile: AddressRecord[] = [];
          this.addressesSrv.readAddressesFromFile(file).then((data) => {
            this.addressesSrv.createAddressesOnDB(data);
            this.spinner.hide();
          });
        } catch (e) {
          this.errorMessage = e;
          this.spinner.hide();
        } finally {
          this.spinner.hide();
        }
      } else {
        console.log('Loading Canceled');
      }
    });
  }

  async exportAddresses() {
    await this.addressesSrv.exportAddresses();
  }

}
