import {Component, Inject, OnInit} from '@angular/core';
import {FileValidator} from "../../../app-reusable-components/file-selector/interfaces/fileValidator";
import {xlsxFileValidator} from "../../../app-reusable-components/file-selector/validators/xlsxFileValidator";
import {Person, Team} from "../team-dialog/interfaces/team.type";
import {CloudFunctionsService, TeamsAmountsToServer} from "../../../cloud-functions/cloud-functions.service";
import {NgxSpinnerService} from "ngx-spinner";
import {FileReaderService} from "../../../app-reusable-components/file-selector/services/file-reader/file-reader.service";
import {ReadXlsService} from "../../../app-reusable-components/file-selector/services/read-xls/read-xls.service";
import {FieldValidator} from "../../../app-reusable-components/file-selector/interfaces/fieldValidator";
import {UserRole} from "../../../types/userRoles.enum";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as _ from "lodash";
import {AuthService} from "../../../app-services/auth/auth.service";
import {DaoService} from "../../../app-dao/dao.service";
import {take} from "rxjs/operators";
import {TeamUtilsService} from "../team-dialog/services/team-utils.service";


@Component({
  selector: 'hvr-team-bulk-dialog',
  templateUrl: './team-bulk-dialog.component.html',
  styleUrls: ['./team-bulk-dialog.component.less']
})
export class TeamBulkDialogComponent implements OnInit {

  errorMessage: string;
  fileValidators: FileValidator[] = [];
  teamFileHeader = ['email', 'name', 'phone', 'car', 'packages'];
  fieldsValidators: Array<FieldValidator> = [];
  dbTeams: Team[];
  failedDrivers = [];
  messages: string[] = [];
  addressGroups: string[];
  assignFromGroup: string;

  constructor(private spinner: NgxSpinnerService,
              private fileReaderService: FileReaderService,
              private readXlsService: ReadXlsService,
              public dialogRef: MatDialogRef<TeamBulkDialogComponent>,
              private authSrv: AuthService,
              private teamsDao: DaoService<Team>,
              private cloudFunctionsSrv: CloudFunctionsService,
              private teamUtils: TeamUtilsService,
              @Inject(MAT_DIALOG_DATA) public data: {
                dbTeams: Team[], addressGroups?: string[]
              }
  ) {
    this.dbTeams = data.dbTeams;
    this.addressGroups = data.addressGroups;
  }

  ngOnInit() {
    this.initFileValidators();
  }

  initFileValidators() {
    const fileValid: FileValidator = {
      messageOnFailure: 'Only xls/xlsx files are allowed',
      validate: xlsxFileValidator
    };
    this.fileValidators.push(fileValid);
  }

  handleFileSelected(event) {
    this.spinner.show();

    if(this.assignFromGroup === 'any') {
      this.assignFromGroup = null;
    }

    this.clearMessages();
    this.newMessage('Now reading the File...');
    let teamsToCreate: Team[] = [];
    let teamsAmounts: TeamsAmountsToServer[] = [];
    const reader = this.fileReaderService.getFileReader(event);
    reader.onload = async () => {
      try {
        let fileTeamsData;
        fileTeamsData = this.readXlsService.readXLS(
          reader,
          this.teamFileHeader,
          null,
          this.fieldsValidators,
          null
        );

        for (let teamRow of fileTeamsData) {
          if (teamRow['car'] && teamRow['car'].toString().toLowerCase() === 'true') {
            if(_.find(teamsToCreate, (team:Team)=>team.driver.email === teamRow['email'].toLowerCase())){
              console.log('skipping already exists driver: ', teamRow['email']);
              continue;
            }
            let currentTeam = this.createTeamByRawData(teamRow);
            teamsToCreate.push(currentTeam);
            if (teamRow['packages'] && teamRow['packages'] > 0) {
                teamsAmounts.push({'teamId': currentTeam.name, amount: teamRow['packages']})
            }
          }
        }
        this.newMessage(`Number of teams to create is: ${teamsToCreate.length}`);

        let {validatedTeams, droppedTeams} = this.validateTeamsToCreate(teamsToCreate);

        if (droppedTeams && droppedTeams.length > 0) {
          this.newMessage(`${droppedTeams.length} teams will not be created`);
          console.log('teams will not be created: ', droppedTeams);
        }

        this.newMessage('Creating drivers...');
        await this.createDriversOnDB(validatedTeams.map(i => i.driver));
        // if (this.failedDrivers.length > 0) {
        //   this.newMessage('this drivers have been failed to create on DB:');
        //   for (let driver of this.failedDrivers) {
        //     this.newMessage(driver.name);
        //   }
        // }

        if (validatedTeams && validatedTeams.length > 0) {
          this.newMessage('Creating teams...');
          this.teamsDao.create('teams', validatedTeams).then(async response => {
              this.newMessage('Setting addresses for teams...');
              await this.setAddressesForTeams(teamsAmounts, this.assignFromGroup);
              this.newMessage('Teams Created Successfully');
            }
          ).catch((err) => {
            this.newMessage(`Error while creating teams ${err}`);
            throw err;
          });
        } else {
          this.newMessage('No teams to create');
        }
      } catch (e) {
        this.errorMessage = e;
        console.log('Error: ', e);
        this.spinner.hide();
      } finally {
        this.spinner.hide();
      }
    };
  };

  newMessage(msg:string){
    this.messages.push(msg);
  }

  clearMessages() {
    this.messages = [];
  }

  createTeamByRawData(rawData): Team {
    let createdTeam: Team = this.teamUtils.createTeam();

    createdTeam.driver.name = rawData['name'];
    createdTeam.driver.email = rawData['email'].toLowerCase();
    createdTeam.driver.phone = this.teamUtils.changePhoneToISR(rawData['phone']);
    createdTeam.driver.meta = {role: UserRole.Driver};
    createdTeam.name = createdTeam.driver.name + ' ' + createdTeam.driver.phone;

    return createdTeam;
  }

  validateTeamsToCreate(teamsToCreate: Team[]): { validatedTeams: Team[], droppedTeams: Team[] } {
    let validatedTeams: Team[] = [];
    let droppedTeams: Team[] = [];
    if (!teamsToCreate || !this.dbTeams || this.dbTeams.length == 0 || teamsToCreate.length == 0) {
      return {validatedTeams: teamsToCreate, droppedTeams: []};
    }
    for (let team of teamsToCreate) {
      if (_.find(this.dbTeams, (i: Team) => i.driver.email.toLowerCase() == team.driver.email.toLowerCase())) {
        // this team driver already has a team, a driver cant have more then one team
        console.log('this.dbTeams: ', this.dbTeams);

        console.log(`The team ${team.name} driver already has a team, a driver cant have more then one team`);

        droppedTeams.push(team);
      } else {
        validatedTeams.push(team);
      }
    }
    return {validatedTeams: validatedTeams, droppedTeams: droppedTeams};
  }

  async createDriversOnDB(drivers: Person[]) {
    if (!drivers) {
      console.log('No Drivers To Create');
      return;
    }
    for (let driver of drivers) {
      console.log('Now creating: ', driver.name);
      try {
        const driverPass = driver.phone.replace(/\+972/, '0');
        await this.authSrv.createUser(driver.email, driverPass, driver);
        console.log('Created: ', driver.name);
      } catch (e) {
        this.failedDrivers.push({driver: driver, error: e});
      }

    }
  }

  setAddressesForTeams(teamsAmounts: TeamsAmountsToServer[], onlyFromSpecificGroup?: string): Promise<any> {
    if (!teamsAmounts || teamsAmounts.length <= 0) {
      console.log('no teams to set addresses for');
      return;
    }
    return this.cloudFunctionsSrv.assignAddressesForTeams(teamsAmounts, onlyFromSpecificGroup)
      .pipe(take(1)).toPromise()
      .then((response) => {
        console.log('response is: ', response);
      }).catch((error) => {
        console.log('error in set addresses for team: ', error)
        this.errorMessage = `error in set addresses for team ${error}`;
      });
  }


}
