import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {Team} from "../../../dashboard/dialogs/team-dialog/interfaces/team.type";
import {Table} from "primeng/table";

@Component({
  selector: 'hvr-select-team-dialog',
  templateUrl: './select-team-dialog.component.html',
  styleUrls: ['./select-team-dialog.component.less']
})
export class SelectTeamDialogComponent implements OnInit {

  teams: Team[] = [];
  selectedTeam: Team;
  loading: boolean = true;

  @ViewChild('dt', {static: false}) table: Table;

  errorMsg: string;

  constructor(public dialogRef: MatDialogRef<SelectTeamDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { teams: Team[] }) {
  }

  ngOnInit() {
    this.teams = this.data.teams;
    this.loading = false;
    console.log('teams to select from are: ', this.teams);
  }

  confirmTeam() {
    this.dialogRef.close({
      team: this.selectedTeam
    });
  }

}
