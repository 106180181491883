<div id="teams-view">

  <header id="header" class="alt">
    <div class="inner">
      <h1>צוות חלוקה: {{team?.name}}</h1>
    </div>
  </header>


  <div id="main-content">
    <div id="team-details">
      <span>ראש הצוות:</span><span>{{team?.driver?.name}}</span>
      <div id="team-messages">
        <b>הודעות לצוות:</b>
        <p>{{teamMessage}}</p>
      </div>
      <div>
        <label>סהכ כתובות לחלוקה</label>
        <div>{{totalAddressesCount}}</div>
      </div>
    </div>

    <div id="addresses">
      <div *ngIf="!hasAddresses()">
        <p>אין כתובות מוקצות לצוות לחלוקה</p>
        <div id="packages-number-selection">
          <div>כמה חבילות העמסת על רכבך?</div>

          <div class="btn-toolbar" role="toolbar" aria-label="...">
            <div class="btn-group" role="group" aria-label="...">
              <button type="button" class="btn btn-primary" [ngClass]="{'disabled': isSetAddressesToTeamDisabled}" (click)="numberOfPackages=1">1</button>
              <button type="button" class="btn btn-primary" [ngClass]="{'disabled': isSetAddressesToTeamDisabled}" (click)="numberOfPackages=2">2</button>
              <button type="button" class="btn btn-primary" [ngClass]="{'disabled': isSetAddressesToTeamDisabled}" (click)="numberOfPackages=3">3</button>
              <button type="button" class="btn btn-primary" [ngClass]="{'disabled': isSetAddressesToTeamDisabled}" (click)="numberOfPackages=4">4</button>
              <button type="button" class="btn btn-primary" [ngClass]="{'disabled': isSetAddressesToTeamDisabled}" (click)="numberOfPackages=5">5</button>
              <button type="button" class="btn btn-primary" [ngClass]="{'disabled': isSetAddressesToTeamDisabled}" (click)="numberOfPackages=6">6</button>
              <button type="button" class="btn btn-primary" [ngClass]="{'disabled': isSetAddressesToTeamDisabled}" (click)="numberOfPackages=7">7</button>
              <button type="button" class="btn btn-primary" [ngClass]="{'disabled': isSetAddressesToTeamDisabled}" (click)="numberOfPackages=8">8</button>
            </div>
          </div>
        </div>

<!--        <input id="packages-number" placeholder="הכנס מספר חבילות" [(ngModel)]="numberOfPackages"/>-->
        <button id="set-addresses-btn" [ngClass]="{'disabled': isSetAddressesToTeamDisabled}" (click)="setAddressForTeam()">{{AddressesToTeamText}}</button>
        <div id="err" *ngIf="errorMsg && errorMsg!=''">{{errorMsg}}</div>
      </div>
      <div id="todo" *ngIf="hasAddresses()">
        <h4>כתובות לחלוקה:</h4>
        <h5 id="current-address" class="center">{{addressRecordToString()}}</h5>
        <h5 id="family-address" class="center">{{addressFamilyToString()}}</h5>
        <h5 id="family-phone" class="center ltr"><a
          href="tel:{{currentAddressRecord['phone']}}">{{currentAddressRecord['phone']}}</a></h5>

        <div id="address-navigator">
          <button mat-raised-button color="accent" style="direction: rtl" (click)="previousAddress()">כתובת הקודמת
          </button>
          <button mat-raised-button color="accent" style="direction: rtl" (click)="nextAddress()">כתובת הבאה</button>
        </div>

        <div id="navigate-section">
          <a id="navigate-link" class="waze-img" style="width: 102px; height: 50px;" target="_blank"
             [href]=getAddressWazeUrl()></a>
        </div>


        <div id="operations">
          <mat-button-toggle-group id="delivery-btns-group" appearance="legacy" name="fontStyle" aria-label="Font Style"
                                   #group="matButtonToggleGroup" [value]="getCurrentDeliveryStatus()">
            <mat-button-toggle id="delivery-success"
                               value="Delivered" (click)="handleDelivery(DeliveryStatus.Delivered)">נמסר
            </mat-button-toggle>
            <mat-button-toggle id="delivery-failure"
                               value="UnDelivered" (click)="handleDelivery(DeliveryStatus.UnDelivered)">לא נמסר
            </mat-button-toggle>
            <mat-button-toggle id="delivery-other" value="Other" (click)="handleDelivery(DeliveryStatus.Other)">אחר
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div id="other-text-section" [hidden]="!showOtherDetails()">
          <input id="other-input" matInput placeholder="הערות לגבי הכתובת הנוכחית..."
                 (change)="reportOtherDelivery($event)"
                 [value]="getOtherInputValue()"
                 (input)="currentAddressRecord?.delivery_status.details = $event.target.value">

        </div>

        <h5 id="current-address-id" class="center">מזהה כתובת:{{currentAddressRecord['id']}}</h5>

        <div id="map_links">
          <a *ngIf="!showMap" (click)="showMap=!showMap" class="center">הצג כתובת על המפה</a>
          <a *ngIf="showMap" (click)="showMap=!showMap" class="center">הסתר מפה</a>
        </div>

        <div id="map" *ngIf="showMap">
          <div class="mapouter" class="delivary-status-{{currentAddressRecord?.delivery_status?.status}}">
            <div class="gmap_canvas">
              <iframe width="600" height="500" id="gmap_canvas" [src]=getAddressMapUrl() frameborder="0" scrolling="no"
                      marginheight="0" marginwidth="0">
              </iframe>
            </div>
          </div>
        </div>

      </div>
      <div id="done"></div>
    </div>
  </div>


  <div id="footer">
    <h2>אנחנו כאן בשבילך לכל שאלה או בעיה</h2>
<!--        <div id="hevre-phone" class="center ltr"><a href="tel:0559209636">0559209636</a> טלפון למוקד</div>-->
    <h3>זמינים בשבילך גם בוואטסאפ</h3>
    <div id="whatsapp" class="whatsapp-img" style="width: 30px; height: 30px;" (click)="sendWhatsappMessage()">
    </div>
  </div>
</div>

