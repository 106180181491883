<div id="message-dialog">
  <h1>{{data?.header}}</h1>
  <div id="message">{{data.message}}</div>
  <div id="operation-btns">
    <button id="possitive-btn" (click)="onPossitive()" class="hvr-button">
      {{data?.possitiveText}}
    </button>

    <button id="negative-btn" (click)="onNegative()" class="hvr-button">
      {{data?.negativeText}}
    </button>
  </div>
</div>
