<div class="dashboard center">
  <h1 class="title no-print-data">Hevre Tovim Dashboard</h1>

  <button (click)="refreshStats()">Refresh</button>
  <div id="graphs">
    <div id="delivery-graph">
      <div id="counters" *ngIf="deliveryStats && deliveryStats.length > 0">
        <span class="counter" style="color: green">Delivered: {{deliveryStats[deliveryPossibleStatus.Delivered]['count']}} | </span>
        <span class="counter" style="color: red;">UnDelivered: {{deliveryStats[deliveryPossibleStatus.UnDelivered]['count']}} | </span>
        <span class="counter" style="color: yellow">Other: {{deliveryStats[deliveryPossibleStatus.Other]['count']}} | </span>
        <span class="counter" style="color: gray">Unknown: {{deliveryStats[deliveryPossibleStatus.Unknown]['count']}}</span>
      </div>
      <hvr-pie id="delivery-pie" *ngIf="showStatsGraph" [slicesConfig]="packagesPieConfig"></hvr-pie>
      <p *ngIf="!showStatsGraph">Loading Graph...</p>
    </div>
    <div id="addresses-graph">
      <hvr-bar [labels]="barLabels" [dataSets]="barDataSets" *ngIf="showBarGraph"></hvr-bar>
    </div>
  </div>
</div>

