import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {AppStateService} from "../app-services/state/app-state.service";
import {WindowService} from "../app-services/window/window.service";
import {AuthService} from "../app-services/auth/auth.service";
import {HevreRouterService} from "../app-services/router/hevre-router.service";
import {NgxSpinnerService} from "ngx-spinner";
import * as firebase from "firebase";
import * as _ from "lodash";
import {TeamUtilsService} from "../dashboard/dialogs/team-dialog/services/team-utils.service";

@Component({
  selector: 'hvr-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.less']
})
export class AuthComponent implements OnInit {
  email = new FormControl('', [Validators.required, Validators.email]);

  password = new FormControl('', [Validators.required]);

  error: string;

  message: string;

  recaptchaVerifier;

  verificationCode: string;

  loginWithPhoneIsActivated: boolean = false;
  showGetUserPhoneNumberVisible: boolean = false;
  showEnterCode: boolean = false;

  userPhoneNumber: string;

  confirmationResult;

  constructor(private stateSrv: AppStateService, private authSrv: AuthService, private windowSrv: WindowService,
              private hvrRouter: HevreRouterService, private spinner: NgxSpinnerService,
              private changeDetectorRef: ChangeDetectorRef, private teamUtils: TeamUtilsService) {
  }

  ngOnInit() {
    this.loginWithEmailNoPassword();
    this.initRecaptcha();
  }

  initRecaptcha() {

    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-with-phone', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        this.loginWithPhoneIsActivated = true;
      }
    });
  }

  ShowGetUserPhoneNumber() {
    this.showGetUserPhoneNumberVisible = true;
    this.loginWithPhoneIsActivated = true;
  }

  handleLoginWithPhone() {
    if (!this.userPhoneNumber) {
      // TODO: add regx to verify phone format
      this.error = 'you must provide a valid phone number';
      return;
    }
    // this.spinner.show();
    // TODO: from some unknowen reason the spinner shows but the hide that comes later can't hide it.
    this.error = null;
    const appVerifier = this.recaptchaVerifier;
    firebase.auth().signInWithPhoneNumber(this.getPhoneNumberFromUserInput(), appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        this.spinner.hide();
        this.confirmationResult = confirmationResult;
        this.showEnterCode = true;
        this.showGetUserPhoneNumberVisible = false;
        this.changeDetectorRef.detectChanges();
      }).catch((error) => {
      this.error = "Error! SMS not sent";
      this.showGetUserPhoneNumberVisible = true;
      this.showEnterCode = false;
      this.spinner.hide();
    });
  }

  async loginWithCode() {
    this.error = null;
    this.spinner.show();
    try {
      await this.authSrv.loginWithCode(this.confirmationResult, this.verificationCode);
    }
    catch(e){
      this.spinner.hide();
      this.error = e;
    }
    const stateUser = this.stateSrv.getUser();
    this.spinner.hide();
    this.hvrRouter.navigateToHomeScreenByRole(stateUser);
  }

  getPhoneNumberFromUserInput() {
    if (!this.userPhoneNumber) {
      return;
    }
    return this.teamUtils.changePhoneToISR(this.userPhoneNumber);
    // return '+16505553434';
  }

  getErrorMessage() {
    this.spinner.hide();
    return this.email.hasError('required') ? 'You must enter a value' :
      this.email.hasError('email') ? 'Not a valid email' :
        '';
  }

  handleLogin() {
    this.error = null;
    this.message = null;
    this.spinner.show();
    this.authSrv.login(this.email.value,
      this.password.value).then(
      async (user) => {
        console.log('login successfull user is: ', user);
        const stateUser = this.stateSrv.getUser();
        this.hvrRouter.navigateToHomeScreenByRole(stateUser);
        this.spinner.hide();
      },
      (failure) => {
        console.log('login failed: ', failure);
        this.spinner.hide();
        this.error = failure.message;
      }
    )
  }

  handleLoginNoPassword() {
    this.error = null;
    this.message = null;
    if (!this.email || _.isNil(this.email.value) || this.email.value === "") {
      this.error = "please provide the email address you register the system with"
      return;
    }
    this.authSrv.sendLoginToEmail(this.email.value);
    this.message = "שלחנו לך לינק להתחברות, בדוק את תיבת המייל שלך"
  }

  loginWithEmailNoPassword() {
    // Confirm the link is a sign-in with email link.
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn');

      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        console.log('i dont have your email');

        // email = window.prompt('Please provide your email for confirmation');
      }
      const that = this;
      // The client SDK will parse the code from the link for you.
      firebase.auth().signInWithEmailLink(email, window.location.href)
        .then(async function (result) {
          console.log('Successfull login')

          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
          const theUser = await that.authSrv.setCurrentUser(result.user);
          that.hvrRouter.navigateToHomeScreenByRole(theUser);
        })
        .catch(function (error) {
          console.log('Error with attempt to login: ', error);
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    } else {
      console.log('no sign in');

    }
  }

}
