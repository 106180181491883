<h1 id="dialog-header">
  Bulk Team Creation
</h1>

<p class="error" *ngIf="errorMessage">{{errorMessage}}</p>

<h3>Step 1: Select Address Group</h3>
<mat-form-field>
  <mat-label>Assign Only From Specific Group?</mat-label>
  <mat-select [(ngModel)]="assignFromGroup">
    <mat-option value="any">Any</mat-option>
    <mat-option *ngFor="let group of addressGroups" value="{{group}}">{{group}}</mat-option>
  </mat-select>
</mat-form-field>

<div>
  <h3>Step 2: Upload your teams file</h3>
  <hvr-file-selector class="no-print-data" (onFileSelected)="handleFileSelected($event)"
                     [selectedFilesValidators]="fileValidators" [disabled]="!assignFromGroup">
  </hvr-file-selector>

  <div id="progress-report">
    <h2>Messages:</h2>
    <span *ngFor="let msg of messages" style="display: block;">{{msg}}</span>
  </div>
</div>
