import {Component, Input, OnInit} from '@angular/core';
import {Person} from "../dialogs/team-dialog/interfaces/team.type";

@Component({
  selector: 'hvr-person-card',
  templateUrl: './person-card.component.html',
  styleUrls: ['./person-card.component.less']
})
export class PersonCardComponent implements OnInit {

  @Input() person: Person;
  constructor() { }

  ngOnInit() {
  }

}
