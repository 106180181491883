import {Injectable, OnInit} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from 'angularfire2/firestore';
import {Observable} from "rxjs";
import * as firebase from 'firebase';
import * as _ from "lodash";


@Injectable({
  providedIn: 'root'
})
export class DaoService<T> implements OnInit {

  collection: AngularFirestoreCollection<T>;
  data: Observable<T[]>;

  constructor(private afs: AngularFirestore) {
  }

  ngOnInit(): void {
  }

  read(collectionName: string): Observable<T[]> {
    try {
      this.collection = this.afs.collection(collectionName);
      return this.collection.valueChanges();
    } catch (e) {
      console.log(`DB Read Error: ${e}`);
    }
  }

  get(collectionName: string, byProperty: string, value: string): Promise<firebase.firestore.Query> {
    this.collection = this.afs.collection(collectionName);

    let query = this.collection.ref.where(byProperty, "==", value);
    return query.get().then(function (results) {
      if (results.empty) {
        console.log("No documents found!");
        return null;
      } else {
        // go through all results
        // results.forEach(function (doc) {
        //   console.log("Document data:", doc.data());
        // });
        return results.docs[0].data();
      }
    }).catch(function (error) {
      console.log("Error getting documents:", error);
      return null;
    });
  }

  async getList(collectionName: string, byProperty: string, value: string): Promise<any> {
    let retVal = [];
    this.collection = this.afs.collection(collectionName);

    let query = this.collection.ref.where(byProperty, "==", value);
    await query.get().then(function (results) {
      if (results.empty) {
        // console.log(`No documents found for collection: ${collectionName}, property: ${byProperty}, value: ${value} `);
        // Promise.reject(null);
        Promise.resolve(null);
      } else {
        // go through all results
        results.forEach(function (doc) {
          // console.log("Document data:", doc.data());
          retVal.push(doc.data());
        });
      }
    }).catch(function (error) {
      console.log("Error getting documents:", error);
      Promise.reject(null);
    });
    return Promise.resolve(retVal);
  }

  //
  create(collectionName:string, data:T[]
  ): Promise<void> {
    try {
      this.collection = this.afs.collection(collectionName);
      // if (data.length == 1) {
      //   return this.collection.add(data[0]);
      // } else {
      const batch = firebase.firestore().batch();
      for (const i of data) {
        batch.set(this.collection.doc<T>(String(i['id'])).ref, i)
      }
      return batch.commit()
      // }
    } catch
      (e) {
      throw new Error(`DB Create Error: ${e}`);
    }
  }

  delete(collectionName
           :
           string, id
           :
           string
  ):
    Promise<void> {
    try {
      this.collection = this.afs.collection(collectionName);
      return this.collection.doc<T>(id).delete();
    } catch
      (e) {
      console.log(`DB Delete Error: ${e}`);
    }
  }

  update(collectionName: string, data: T[], id ?: any): Promise<void> {
    try {
      this.collection = this.afs.collection(collectionName);
      if (id) {
        const item: T = _.find(data, (i) => i['id'] === id);
        if (!item) {
          console.log(`Update Error, no item with id ${id} in data`);
          return;
        }
        return this.collection.doc<T>(String(item['id'])).update(item);
      } else {
        const batch = firebase.firestore().batch();
        for (const i of data) {
          batch.update(this.collection.doc<T>(String(i['id'])).ref, i)
        }
        return batch.commit()
      }
    } catch (e) {
      console.log(`DB Update Error: ${e}`);
    }
  }

}
