import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {AppStateService} from "./app-services/state/app-state.service";
import {User} from "./types/user.type";
import {AuthService} from "./app-services/auth/auth.service";
import {HevreRouterService} from "./app-services/router/hevre-router.service";
import {UserRole} from "./types/userRoles.enum";
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'hevre';
  user: User;
  appVersion: string = environment.appVersion;

  constructor(private state: AppStateService, private hvrAuth: AuthService,
              private ref: ChangeDetectorRef, private hvrRouter: HevreRouterService
  ) {
  }

  ngOnInit() {
    this.hvrAuth.lookForUser();
    this.state.getStateListener().subscribe(state => {
      this.user = state.user;
      if (this.user) {
        this.ref.detectChanges();
      }
      console.log('in app init user is: ', this.user);
    })
  }

  ngOnDestroy(): void {
    this.state.getStateListener().unsubscribe();
  }

  handleLogOut() {
    this.hvrAuth.logout().then(() => {
      this.hvrRouter.navigate(['login']);
    });

  }

  goHome() {
    this.hvrRouter.navigate(['dashboard']);
  }

  isAdmin(): boolean {
    return this.user ?  this.user.role === UserRole.Admin : false;
  }

}
