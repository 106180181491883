import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import {TeamDialogComponent} from "../../dashboard/dialogs/team-dialog/team-dialog.component";

@Component({
  selector: 'hvr-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.less']
})
export class MessageDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<MessageDialogComponent>, @Inject(MAT_DIALOG_DATA) public data:MessageDialogData) { }

  ngOnInit() {
  }

  onPossitive() {
    this.dialogRef.close(true);
  }

  onNegative() {
    this.dialogRef.close(false);
  }

}

export interface MessageDialogData{
  message: string,
  includePossitive?:boolean,
  includeNegative?: boolean,
  header?: string
  possitiveText?: string,
  negativeText?: string

}
