<div id="team-card" class="no-print-data" [style.background]="markUnDoneTeam()">
  <h1>{{team.name}}</h1>

  <div id="whatsapp" class="whatsapp-img" style="width: 30px; height: 30px;" (click)="sendWhatsappMessage()">
  </div>

  <button mat-raised-button color="primary" style="direction: rtl; float: right;" (click)="handlePrintAddresses()">שלח
    כתובות בוואטסאפ
  </button>
  <i id="edit-btn" class="material-icons md-24" (click)="handleEditTeam()">edit</i>
  <i id="delete-btn" class="material-icons md-24" (click)="handleDeleteTeam()">delete</i>

  <h3>Driver:</h3>
  <hvr-person-card [person]="team.driver"></hvr-person-card>
  <div id="members-section">
    <h3>Members:</h3>
    <hvr-person-card *ngFor="let member of team.members" [person]="member"></hvr-person-card>
  </div>

  <div>This team will deliver to the following addresses:</div>
  <br/>
  <div class="team-address" *ngFor="let address of teamAddresses" [style.color]="getAddressStyle(address)" (click)="seeAddress(address)">
    {{getAddressDisplay(address)}}
  </div>

  <br/>
  <div><b>Delivery started at:</b></div>
  <div>{{team.deliveryStartTime}}</div>

</div>

<hvr-address-cards class="print-data" [printData]="printData" *ngIf="isPrintAddressesAvailable()"></hvr-address-cards>

