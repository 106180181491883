<nav class="navbar navbar-expand-lg navbar-light bg-light main-menu">
  <a class="navbar-brand" (click)="routeTo('dashboard')">Main Dashboard</a>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">

      <li class="nav-item dropdown">
        <a class="nav-link" href="#" id="teamsNavbarDropdown" role="button" data-toggle="dropdown"
           aria-haspopup="true" aria-expanded="false" (click)="routeTo('manage-teams')">
          Manage Teams
        </a>
<!--        <div class="dropdown-menu" aria-labelledby="navbarDropdown">-->
<!--          <a class="dropdown-item" (click)="routeTo('manage-teams')">Teams Dashboard</a>-->
<!--        </div>-->
      </li>

      <li class="nav-item dropdown">
        <a class="nav-link" href="#" id="addressesNavbarDropdown" role="button" data-toggle="dropdown"
           aria-haspopup="true" aria-expanded="false" (click)="routeTo('manage-addresses')">
          Manage Addresses
        </a>
<!--        <div class="dropdown-menu" aria-labelledby="navbarDropdown">-->
<!--          <a class="dropdown-item" (click)="routeTo('manage-addresses')">Addresses Dashboard</a>-->
<!--        </div>-->
      </li>

    </ul>
  </div>

</nav>



