import {Injectable, NgZone} from '@angular/core';
import {Router} from "@angular/router";
import {User} from "../../types/user.type";
import {UserRole} from "../../types/userRoles.enum";
import {AuthService} from "../auth/auth.service";
import {WindowService} from "../window/window.service";

@Injectable({
  providedIn: 'root'
})
export class HevreRouterService {

  constructor(private router: Router, private ngZone: NgZone, private authSrv: AuthService,
              private windowRef: WindowService) { }

  navigate(pathArray: string[], outside: boolean = false, queryParams = null): Promise<boolean> {
    if (outside) {
      window.open(pathArray.join('/'), '_blank');
      return;
    }

    const urlBeforeNavigating = this.router.url;
    if(urlBeforeNavigating.includes(pathArray[0])){
      this.ngZone.run(() => this.router.navigate(pathArray,{queryParams: queryParams})).then(
        ()=>this.windowRef.reloadWindow()
        );
      return;
    }

    // without this i'm getting an error in console "Navigation triggered outside Angular zone, did you forget to call 'ngZone.run()'?" and the site is broken.
    this.ngZone.run(() => this.router.navigate(pathArray,{queryParams: queryParams})).then();
  }

  navigateToHomeScreenByRole(user:User) {
    if (!user || !user.role) {
      // bad!!! we have a logged in user with no role
      // TODO: navigate to error page
      console.log('user with no role! logging out now...', user);
      this.authSrv.logout();
      return;
    }

    if (user.role == UserRole.Admin) {
      this.navigate(['dashboard']);
    }
    else {
      this.navigate(['team-view']);
    }
  }
}
