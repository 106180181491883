import {AfterContentInit, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Person, Team} from "./interfaces/team.type";
import {TeamUtilsService} from "./services/team-utils.service";
import * as _ from 'lodash';
import {UserRole} from "../../../types/userRoles.enum";
import {AddressRecord} from "../../address-record.type";
import {AddressUtilsService} from "../../../app-services/addresses/address-utils.service";
import {DeliveryStatus} from "../../../enums/enums";

@Component({
  selector: 'hvr-team-dialog',
  templateUrl: './team-dialog.component.html',
  styleUrls: ['./team-dialog.component.less']
})
export class TeamDialogComponent implements OnInit {

  tempMember: Person;
  tempDriver: Person;
  memberFormVisible: boolean = false;
  displayMembers: boolean = false;
  driverConfirmed: boolean = false;
  errorMsg: string = null;
  team: Team;
  processing: boolean = false;
  addressesGroups: string[];
  addressesAssignedAmount: number;
  assignFromGroup: string;
  requestedAddressesAmount: number = 0;
  teamAddresses: AddressRecord[] = [];
  showAddressesList: boolean = false;

  constructor(public dialogRef: MatDialogRef<TeamDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { team: Team, groups?: string[], teamAddresses?: AddressRecord[] },
              private addressUtilsSrv: AddressUtilsService,
              private teamUtils: TeamUtilsService) {
    this.team = this.data.team;
    this.addressesGroups = this.data.groups || [];
    this.addressesAssignedAmount = this.data.hasOwnProperty('teamAddresses') ? this.data.teamAddresses.length : 0;
    this.teamAddresses = this.data.teamAddresses;
  }

  ngOnInit() {
    if (!this.team) {
      this.team = this.teamUtils.createTeam();
      this.tempDriver = this.teamUtils.createEmptyPerson();
    } else {
      this.tempDriver = this.team.driver;
    }
    this.tempMember = this.teamUtils.createEmptyPerson();
    this.team.isDirty = false;
  }

  handleConfirmDriver() {
    this.errorMsg = null;
    const personValidationResult = this.teamUtils.validatePerson(this.tempDriver, ['name', 'phone']);
    if (personValidationResult && !personValidationResult.valid) {
      this.errorMsg = personValidationResult.reason;
      return;
    }
    this.driverConfirmed = true;
    this.team.driver = this.tempDriver;
    this.team.driver.meta['role'] = UserRole.Driver;
    this.team.name = this.team.driver.name + ' ' + this.team.driver.phone;
    this.team.driver.phone = this.teamUtils.changePhoneToISR(this.team.driver.phone);
    this.team.isDirty = true;
  }

  handleEditDriver() {
    this.tempDriver = this.team.driver;
    this.errorMsg = null;
    this.driverConfirmed = false;
  }

  showMemberForm() {
    this.memberFormVisible = true;
  }

  showMembers() {
    this.displayMembers = true;
  }

  hideMembers() {
    this.displayMembers = false;
  }

  handleAddMember() {
    if (this.tempMember.name && this.tempMember.name != '') {
      this.tempMember.meta['confirmed'] = true;
      this.team.members.push(_.clone(this.tempMember));
    }
    this.tempMember = this.teamUtils.createEmptyPerson();
    this.memberFormVisible = false;
    this.team.isDirty = true;
  }


  confirmTeam() {
    this.processing = true;
    let {result, reason} = this.canConfirmTeam();
    if (result) {
      this.requestedAddressesAmount = _.toNumber(this.requestedAddressesAmount);
      if (this.requestedAddressesAmount > 0) {
        this.team.isDirty = true;
      }
      this.dialogRef.close({
        team: this.team, meta: {
          assignFromGroup: this.assignFromGroup,
          requestedAddressesAmount: this.requestedAddressesAmount
        }
      });

    } else {
      this.errorMsg = reason;
    }
    this.processing = false;
  }


  canConfirmTeam(): { result: boolean, reason: string } {
    if (!this.team || !this.team.id) {
      return {result: false, reason: 'Failed to create valid team'};
    }
    if (!this.team.driver || !this.team.driver.phone) {
      return {result: false, reason: 'Invalid driver details'};
    }
    if (!this.team.name) {
      return {result: false, reason: 'Team name is mandatory'};

    }
    if (_.toNumber(this.requestedAddressesAmount) < 0) {
      return {result: false, reason: 'Invalid addresses amount'};
    }
    return {result: true, reason: ''};
  }

  getAddressDisplay(address: AddressRecord): string {
    return this.addressUtilsSrv.getAddressDisplay(address);
  }

  removeAddress(address: AddressRecord) {
    this.addressUtilsSrv.clearAddressTeam(address, (sucess) => {
      _.remove(this.teamAddresses, (i) => i.id === address.id);
      this.addressesAssignedAmount -= 1;
    });
  }

}
