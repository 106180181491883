import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BarsConfig} from "./interfaces/barsConfig";

@Component({
  selector: 'hvr-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.less']
})
export class BarComponent implements OnInit, OnChanges {

  @Input() labels: string[];
  @Input() dataSets: BarsConfig[];
  data: any;

  constructor() {

  }

  ngOnInit() {
    this.data = {
      labels: this.labels,
      datasets: this.dataSets
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.data = {
      labels: this.labels,
      datasets: this.dataSets
    }
    console.log('dataset is: ', this.data);

  }

}
