import { Component, OnInit } from '@angular/core';
import {HevreRouterService} from "../app-services/router/hevre-router.service";

@Component({
  selector: 'hvr-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less']
})
export class MenuComponent implements OnInit {

  constructor(private hvrRouter:HevreRouterService) { }

  ngOnInit() {
  }

  routeTo(link: string){
    this.hvrRouter.navigate([link]);
  }



}
