import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileReaderService {

  constructor() {
  }

  public getFileReader(file) {
    let reader = new FileReader();

    if (!FileReader.prototype.readAsBinaryString) {
      FileReader.prototype.readAsBinaryString = function (fileData) {
        let binary = '';
        let pt = this;
        let reader = new FileReader();
        reader.onload = function (e) {
          let bytes = new Uint8Array(reader.result as ArrayBuffer);
          let length = bytes.byteLength;
          for (let i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          //pt.result  - readonly so assign binary
          pt.content = binary;
          pt.onload();
        };
        reader.readAsArrayBuffer(fileData);
      }
    }
    reader.readAsBinaryString(file);

    return reader;
  }
}
