import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {SlicesConfig} from "../app-reusable-components/charts/pie/interfaces/slicesConfig";
import {AddressesService} from "../app-services/addresses/addresses.service";
import {AddressRecord} from "./address-record.type";
import {Subscription} from "rxjs";
import {DeliveryStatus} from "../enums/enums";
import {BarsConfig} from "../app-reusable-components/charts/bar/interfaces/barsConfig";
import * as _ from "lodash";

@Component({
  selector: 'hvr-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit, OnDestroy {
  private packagesPieConfig: SlicesConfig = {
    labels: ['% Delivered', '% UnDelivered', '% Other', '% Unknown'],
    // initialValues: [50, 20, 20, 10],
    initialValues: [100, 0, 0, 0],
    slicesColors: ["#317645", "#FF412B", "#FFCE56", "#808080"],
    slicesHoverColors: ["#36A2EB", "#36A2EB", "#36A2EB", "#36A2EB"]
  };

  private barLabels: string[] = ['Addresses For Teams'];

  private barDataSets: BarsConfig[] = [
    {
      label: 'Total Addresses',
      backgroundColor: '#42A5F5',
      borderColor: '#1E88E5',
      data: [0, 0]
    },
    {
      label: 'Set To Teams',
      backgroundColor: '#9CCC65',
      borderColor: '#7CB342',
      data: [0, 0]
    },
    {
      label: 'Unset Packages',
      backgroundColor: '#DEA901',
      borderColor: '#7CB342',
      data: [0, 0]
    }
  ];

  addressesSubsciption: Subscription;
  addressesData: AddressRecord[];
  showStatsGraph: boolean = false;
  showBarGraph: boolean = false;
  deliveryPossibleStatus = DeliveryStatus;
  deliveryStats;

  constructor(private addressesSrv: AddressesService, private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    console.log('in dashboard');
    this.addressesData = this.addressesSrv.addressesOnDB$.getValue();
    this.addressesSubsciption = this.addressesSrv.addressesOnDB$.subscribe((value: AddressRecord[]) => {
      this.addressesData = value;
      this.refreshStats();
    });
  }

  refreshStats() {
    this.refreshAddressesStats();
    this.refreshDeliveryStats();
  }

  refreshDeliveryStats() {
    this.showStatsGraph = false;
    this.deliveryStats = this.addressesSrv.getDelivaryStats(this.addressesData);
    console.log('stats: ', this.deliveryStats);
    if (this.deliveryStats && this.deliveryStats.length > 0) {
      this.packagesPieConfig.initialValues[0] = this.deliveryStats[this.deliveryPossibleStatus.Delivered]['percentage'];
      this.packagesPieConfig.initialValues[1] = this.deliveryStats[this.deliveryPossibleStatus.UnDelivered]['percentage'];
      this.packagesPieConfig.initialValues[2] = this.deliveryStats[this.deliveryPossibleStatus.Other]['percentage'];
      this.packagesPieConfig.initialValues[3] = this.deliveryStats[this.deliveryPossibleStatus.Unknown]['percentage'];
      this.showStatsGraph = true;
    }

  }

  refreshAddressesStats() {
    if (!this.addressesData || this.addressesData.length === 0) {
      return;
    }

    let addressesWithTeamAmount = _.filter(this.addressesData, i => !_.isNil(i.team)).length;
    this.showBarGraph = false;
    this.barDataSets[0].data = [this.addressesData.length, 0];
    this.barDataSets[1].data = [addressesWithTeamAmount, 0];
    this.barDataSets[2].data = [this.addressesData.length - addressesWithTeamAmount, 10];
    this.showBarGraph = true;
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.addressesSubsciption.unsubscribe();
  }

}



