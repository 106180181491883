<div class="login-container">

  <div id="login-header">
    <h1 class="login-header">החברה הטובים, ישומון חלוקה</h1>
  </div>

  <div class="auth-form">
    <form class="auth-form-obj" [hidden]="loginWithPhoneIsActivated">
      <mat-form-field class="form-field">
        <input matInput placeholder="Email" type="email" [formControl]="email" required>
        <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
      </mat-form-field>

      <mat-form-field class="form-field">
        <input matInput placeholder="Password" type="password" [formControl]="password" required>
      </mat-form-field>

      <button mat-raised-button color="primary" (click)="handleLogin()">כניסה</button>

<!--      <div>או</div>-->
<!--      &lt;!&ndash;      <button mat-raised-button color="primary" (click)="handleLoginNoPassword()">שלחו לי למייל קישור לכניסה ללא סיסמא</button>&ndash;&gt;-->
<!--      <button id="sign-in-with-phone" mat-raised-button color="primary" [hidden]="loginWithPhoneIsActivated"-->
<!--              (click)="ShowGetUserPhoneNumber()">היכנס עם קוד חד-->
<!--        פעמי-->
<!--      </button>-->
    </form>

    <div id="login-with-phone-code" *ngIf="loginWithPhoneIsActivated">
      <div *ngIf="showGetUserPhoneNumberVisible">
        <input matInput placeholder="הכנס את מספר הטלפון שלך" [(ngModel)]="userPhoneNumber">
        <div style="color:red">מספר הטלפון מספרים בלבד לדוגמא: 0541234567</div>
        <button mat-raised-button color="primary"
                (click)="handleLoginWithPhone()">שלח לי קוד חד
          פעמי לכניסה
        </button>
      </div>

      <div *ngIf="showEnterCode">
        <input matInput placeholder="הכנס את הקוד שקיבלת" [(ngModel)]="verificationCode">
        <button mat-raised-button color="primary" (click)="loginWithCode()">היכנס</button>
      </div>
    </div>
    <div id="error" *ngIf="error">{{error}}</div>
    <div id="message" *ngIf="message">{{message}}</div>
  </div>


</div>

