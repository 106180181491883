import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {Team} from "../dialogs/team-dialog/interfaces/team.type";
import {TeamDialogComponent} from "../dialogs/team-dialog/team-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import * as _ from "lodash";
import {NgxSpinnerService} from "ngx-spinner";
import {AddressRecord} from "../address-record.type";
import {TeamChange} from "../../manage-teams/team-change.type";
import {TeamUtilsService} from "../dialogs/team-dialog/services/team-utils.service";
import {AddressUtilsService} from "../../app-services/addresses/address-utils.service";
import {HevreRouterService} from "../../app-services/router/hevre-router.service";
import {DeliveryStatus} from "../../enums/enums";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'hvr-team-card',
  templateUrl: './team-card.component.html',
  styleUrls: ['./team-card.component.less']
})
export class TeamCardComponent implements OnInit, OnChanges {

  @Input() team: Team;
  @Input() availableAddressesGroups: string[];
  @Output() onTeamEdited: EventEmitter<TeamChange> = new EventEmitter();
  printData;
  @Output() onTeamDeleted: EventEmitter<Team> = new EventEmitter();
  teamAddresses: AddressRecord[];
  deliveryPossibleStatus = DeliveryStatus;

  constructor(private dialog: MatDialog,
              private spinner: NgxSpinnerService,
              private addressUtilsSrv: AddressUtilsService,
              private teamsUtils: TeamUtilsService,
              private sanitization: DomSanitizer,
              private hvrRouter: HevreRouterService,
              private HevreRouterSrv: HevreRouterService) {
  }

  async ngOnInit() {
    this.teamAddresses = await this.teamsUtils.getAddressesOfTeam(this.team);
    // console.log('team address: ', this.team.name + ':' + this.teamAddresses);

  }

  handleEditTeam() {
    console.log('edit clicked');
    this.openEditTeamDialog();
  }

  openEditTeamDialog() {
    const dialogRef = this.dialog.open(TeamDialogComponent, {
      width: '75%',
      data: {
        team: _.clone(this.team),
        groups: this.availableAddressesGroups,
        teamAddresses: this.teamAddresses
      }
    });

    dialogRef.afterClosed().subscribe(dialogResults => {

      if (!dialogResults) {
        return;
      }

      let teamReturened = dialogResults.team;
      if (teamReturened) {
        this.onTeamEdited.emit({
          'teamBefore': this.team,
          'teamAfter': teamReturened,
          'assignAmount': dialogResults.hasOwnProperty('meta') &&
          dialogResults.meta.hasOwnProperty('requestedAddressesAmount') ?
            dialogResults.meta.requestedAddressesAmount : 0,
          'assignFromGroup': dialogResults.hasOwnProperty('meta') &&
          dialogResults.meta.hasOwnProperty('assignFromGroup') ? dialogResults.meta.assignFromGroup : null
        });

        this.team = teamReturened;
      }
    })
  }

  isPrintAddressesAvailable(): boolean {
    return !(!this.printData || this.printData.length === 0);
  }

  async handlePrintAddresses() {
    this.teamAddresses = await this.teamsUtils.getAddressesOfTeam(this.team);
    this.sendAddressesViaWhatsapp();

    // this.printData = {teamName: this.team.name, teamDriver: this.team.driver.name, rawAddresses: this.teamAddresses};
  }

  handleDeleteTeam() {
    if (confirm('Are you sure you want to delete this team from the database?')) {
      this.spinner.show();
      this.onTeamDeleted.emit(this.team);
    }
  }

  getAddressDisplay(address: AddressRecord): string {
    return this.addressUtilsSrv.getAddressDisplay(address);
  }

  getAddressStyle(address: AddressRecord) {

    let color = '#000000';
    switch (address.delivery_status.status) {
      case this.deliveryPossibleStatus.Delivered: {
        color = '#00ff00';
        break;
      }
      case this.deliveryPossibleStatus.UnDelivered: {
        color = '#ff0000';
        break;
      }
      case this.deliveryPossibleStatus.Other: {
        color = '#0000ff';
        break;
      }
    }

    return color;
  }

  markUnDoneTeam(){
    let background = '#ffffff';
    if (_.find(this.teamAddresses, (address: AddressRecord) =>
      address.delivery_status.status === this.deliveryPossibleStatus.Unknown)) {
      background = '#ffff00';
    }

    return background;
  }

  seeAddress(addressRecord: AddressRecord) {
    this.hvrRouter.navigate(['manage-addresses'], false, {address: addressRecord.address});
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.teamAddresses = await this.teamsUtils.getAddressesOfTeam(this.team);

  }

  sendWhatsappMessage() {
    const initialMessage = "היי כאן מוקד החברה הטובים";
    const phone = this.team.driver.phone.replace(/\+/, '');
    let whatsappUrl = `https://api.whatsapp.com/send?phone=${phone}&text=${initialMessage}&source=&data=&app_absent=`;
    this.HevreRouterSrv.navigate([whatsappUrl], true);
  }

  sendAddressesViaWhatsapp() {
    const messagePrefix = `
    היי הנה רשימת הכתובות שלך:
    `;
    const whatsappNewLine = "%0A";
    let addressesMessage = _.join(this.teamAddresses.map(address =>
      `${address.name} - ${address.address} ${address.city} (${address.phone})`), `,${whatsappNewLine}`);
    const messageSufix = ".תודה מהחברה הטובים"
    let finalMessage = `${messagePrefix}${whatsappNewLine}${addressesMessage}${whatsappNewLine}${messageSufix}`
    const phone = this.team.driver.phone.replace(/\+/, '');
    let whatsappUrl = `https://api.whatsapp.com/send?phone=${phone}&text=${finalMessage}&source=&data=&app_absent=`;
    this.HevreRouterSrv.navigate([whatsappUrl], true);

  }

}
