<div id="addresses-section">
  <h2>Addresses</h2>
  <div>
    <hvr-table [displayedColumns]="displayedColumns" (doneLoading)="onAddressesChanged($event)"
               [data]="addressesData" [refreshTable]="refresher" [filterString]="filterTableByString"></hvr-table>

    <h3>Load Addresses File:</h3>
    <hvr-file-selector (onFileSelected)="handleAddressesFileSelected($event)" [selectedFilesValidators]="fileValidators">
    </hvr-file-selector>
    <button mat-icon-button (click)="exportAddresses()" matTooltip="Export Addresses to Csv"
            class="hvr-button no-print-data">
    <mat-icon>file_copy</mat-icon>
    </button>

    <br/>
    <br/>

    <button mat-icon-button (click)="handleDeleteAddresses()" matTooltip="DELETE ALL ADDRESSES"
            class="hvr-button no-print-data" style="background-color: red;">
      <mat-icon>delete_forever</mat-icon>
    </button>

  </div>
</div>
