<div class="print-data" *ngIf="printData">
  <div class="table-container" *ngFor="let twelve of printData.addresses; let lastOne = last"
       [class.start-new-page-after]="!lastOne">
    <header class="hvr-rtl">
      <img [src]=HEVRE_LOGO_IMG height="50" width="127" class="pull-left logo-image">
      <h1>כתובות לחלוקה</h1>
      <h3>{{ printData.teamName }}&nbsp;&nbsp;&#149;&nbsp;
        {{ printData.teamDriver }}&nbsp;&nbsp;&#149;&nbsp;
      </h3>
    </header>

    <div class="scissors-container">
      <img [src]=SCISSORS_IMG height="30" width="21" class="left-image" [class.hide-it]="twelve.length == 1">
      <img [src]=SCISSORS_IMG height="30" width="21" class="middle-image">
      <img [src]=SCISSORS_IMG height="30" width="21" class="right-image" [class.hide-it]="twelve.length == 1">
    </div>

    <table class="print-table">
      <tr *ngFor="let pair of twelve">
        <td [class.hide-scnd]="!pair.scnd" pull-left>
          <div class="address-info">
            <div class="full-name">{{ pair.frst.address + ' ' + pair.frst.city }}</div>
            <div class="info-data"><span translate>שם המקבל</span><span>: {{ pair.frst.Name }}</span></div>
            <div class="info-data"><span translate>טלפון המקבל</span><span>: {{ pair.frst.phone }}</span></div>
            <div class="site-address">www.hevretovim.org</div>
          </div>
          <!--<img [src]=MONSTER_IMG height="139" width="180" class="pull-right monster-image">-->
        </td>
        <td [class.hide-me]="!pair.scnd" pull-left>
          <div class="address-info">
            <div class="full-name">{{ pair.scnd?.address + ' ' + pair.scnd?.city }}</div>
            <div class="info-data"><span translate>שם המקבל</span><span>: {{ pair.scnd?.Name }}</span></div>
            <div class="info-data"><span translate>טלפון המקבל</span><span>: {{ pair.scnd?.phone }}</span></div>
            <div class="site-address">www.hevretovim.org</div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
