<div style="padding: 32px">

  <div class="example-container mat-elevation-z8">

    <mat-radio-group aria-label="Select an option">

      <mat-radio-button class="radio-btn success" value="{{deliveryPossibleStatus.Delivered}}"
                        (click)="applyFilterByDeliveryStatus(deliveryPossibleStatus.Delivered)">Delivered
        ({{getDeliveryCounterByDeliveryStatus(deliveryPossibleStatus.Delivered)?.counter}})
      </mat-radio-button>

      <mat-radio-button class="radio-btn failure" value="{{deliveryPossibleStatus.UnDelivered}}"
                        (click)="applyFilterByDeliveryStatus(deliveryPossibleStatus.UnDelivered)">UnDelivered
        ({{getDeliveryCounterByDeliveryStatus(deliveryPossibleStatus.UnDelivered)?.counter}})
      </mat-radio-button>

      <mat-radio-button class="radio-btn other" value="{{deliveryPossibleStatus.Other}}"
                        (click)="applyFilterByDeliveryStatus(deliveryPossibleStatus.Other)">Other
        ({{getDeliveryCounterByDeliveryStatus(deliveryPossibleStatus.Other)?.counter}})
      </mat-radio-button>

      <mat-radio-button class="radio-btn unknown" value="{{deliveryPossibleStatus.Unknown}}"
                        (click)="applyFilterByDeliveryStatus(deliveryPossibleStatus.Unknown)">Unknown
        ({{getDeliveryCounterByDeliveryStatus(deliveryPossibleStatus.Unknown)?.counter}})
      </mat-radio-button>

      <div>
        <mat-radio-button class="radio-btn" value="-1" (click)="applyFilterByDeliveryStatus(null)">All Addresses
          ({{dataList.length}})
        </mat-radio-button>


        <mat-radio-button class="radio-btn" value="100" (click)="applyFilterByHasTeam(false)">
          Addresses With No Team
        </mat-radio-button>
        <mat-radio-button class="radio-btn" value="200" (click)="applyFilterByHasTeam(true)">
          Addresses With Team
        </mat-radio-button>
      </div>

    </mat-radio-group>

    <mat-form-field class="filter">
      <input matInput (keyup)="applyFilterByAddress($event.target.value)" placeholder="Search" value="{{filterString}}">
    </mat-form-field>

    <mat-table #table [dataSource]="dataSource">

      <ng-container matColumnDef="position">
        <mat-header-cell *cdkHeaderCellDef>No.</mat-header-cell>
      </ng-container>

      <ng-container *ngFor="let col of displayedColumns;" [cdkColumnDef]="col">
        <mat-header-cell *cdkHeaderCellDef> {{col | uppercase}} </mat-header-cell>
        <mat-cell class="cell" *cdkCellDef="let row">

          <ng-container *ngIf="isStandardColumn(col)">{{getRowDataByCol(row, col)}}</ng-container>

          <ng-container *ngIf="col.toLowerCase()=='status'">
            <mat-select [value]="deliveryPossibleStatus[row.delivery_status.status]">
              <mat-option *ngFor="let status of deliveryOptions"
                          (click)="changeAddressDeliveryStatus(row, status)" value="{{status}}">{{status}}
              </mat-option>
            </mat-select>
          </ng-container>

          <ng-container *ngIf="col.toLowerCase()=='team'">
            <div *ngIf="!teamsList">No Teams Found In System</div>

            <button class="set-team-btn" mat-icon-button matTooltip="Set Team" *ngIf="!checkForTeam(row)"
                    (click)="setTeamForAddress(row)">
              <mat-icon>local_shipping</mat-icon>
            </button>

            <button class="set-team-btn" mat-icon-button matTooltip="Change Team" *ngIf="checkForTeam(row)"
                    (click)="setTeamForAddress(row)">
              <mat-icon>compare_arrows</mat-icon>
            </button>

            <button class="see-team-btn" mat-icon-button matTooltip="See Team" *ngIf="checkForTeam(row)"
                    (click)="seeAddressTeam(row)">
              <mat-icon>remove_red_eye</mat-icon>
            </button>

            <button class="clear-team-btn" mat-icon-button matTooltip="Clear Team" *ngIf="checkForTeam(row)"
                    (click)="clearAddressTeam(row)">
              <mat-icon>delete</mat-icon>
            </button>

          </ng-container>
        </mat-cell>
      </ng-container>


      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

      <mat-row class="table-row" *matRowDef="let row; columns: displayedColumns;" [style]="row.style"></mat-row>
    </mat-table>

    <div *ngIf="data.length === 0" id="empty-message">No records found</div>

    <!--<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>-->
  </div>
</div>
