import {Injectable} from '@angular/core';
import {FieldValidator} from "../../interfaces/fieldValidator";
import {ValidationDataResult} from "../../interfaces/validationDataResult";
import * as xlsx from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ReadXlsService {

  constructor() {
  }

  public readXLS(reader, fields, mandatoryFields?: Array<string>,
                 fieldValidators?: Array<FieldValidator>, fieldsAliasing?) {
    let historyFieldValues = fields.reduce((fieldObject, currentFieldName, index, array) => {
      fieldObject[currentFieldName] = [];
      return fieldObject;
    }, {});


    let retVal = [];
    let data;
    if (reader['result']) {
      data = reader['result'];
    } else {
      data = reader['content'];
    }

    let workbook = xlsx.read(data, {type: 'binary'});
    let sheet = workbook.Sheets['Sheet1'];

    let headerFound = false;
    for (let row of xlsx.utils.sheet_to_json(sheet, {header: fields})) {
      if (!headerFound) {
        // check if this row is the header
        for (let field of fields) {
          if (!row[field]) {
            headerFound = false;
            break;
          } else {
            headerFound = true;
          }
        }
        continue;
      }

      if (mandatoryFields) {
        let mandatoryPass = this.checkForMandatoryFields(row, mandatoryFields);
        if (!mandatoryPass[0]) {
          console.log(mandatoryPass[1]);
          throw mandatoryPass[1];
        }
      }

      if (fieldValidators) {
        let validationResult: ValidationDataResult;
        for (let validator of fieldValidators) {
          if (validator.hasOwnProperty('validate')) {
            validationResult = this.runValidatorOnField(validator, row, historyFieldValues);
            if (!validationResult.isValid)
              throw validationResult.messageOnFailure;
          }

        }
      }

      this.addHistoryValuesForCheckingDuplication(row, historyFieldValues, fields);
      row = fieldsAliasing ? this.changeAliasNames(row, fieldsAliasing) : row;
      retVal.push(row);
    }

    return retVal;
  }

  private changeAliasNames(row, aliasDict) {
    let retVal = {};
    for (let name in row) {
      if (aliasDict.hasOwnProperty(name)) {
        let newName = aliasDict[name]
        retVal[newName] = row[name]
      } else {
        retVal[name] = row[name]
      }
    }
    return retVal;
  }


  private addHistoryValuesForCheckingDuplication(row, historyFieldValues, fields) {
    for (let field of fields) {
      historyFieldValues[field].push(row[field]);
    }
  }

  private checkForMandatoryFields(row, mandatoryFields: Array<string>): [boolean, string] {
    for (let mandatoryField of mandatoryFields) {
      if (row[mandatoryField] == null || row[mandatoryField].trim() === '') {
        return [false, `Invalid file, ${mandatoryField} is mandatory.`];
      }
      if (row[mandatoryField].length > 32) {
        return [false, `Invalid file, maximum length of ${mandatoryField} should be 32 chars.`];
      }
    }

    return [true, ""];
  }

  private runValidatorOnField(validator: FieldValidator, row, historyFieldValues): ValidationDataResult {
    return validator.validate(validator.fieldName, row, historyFieldValues);


  }

  exportArrayOfArraysAsCsv(arrayOfArrays: Array<Array<string>>, fileName: string) {
    let csvContent = "data:text/csv;charset=utf-8,"
      + arrayOfArrays.map(e => e.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName+".csv");
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file.
    document.body.removeChild(link);
  }
}
