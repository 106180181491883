import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Team} from "../../../dashboard/dialogs/team-dialog/interfaces/team.type";

@Component({
  selector: 'hvr-delivery-summery-dialog',
  templateUrl: './delivery-summery-dialog.component.html',
  styleUrls: ['./delivery-summery-dialog.component.less']
})
export class DeliverySummeryDialogComponent implements OnInit {
  summeryMessage: string;
  deliveryStatusMessage: string;
  isDoneDelivery: boolean = false;
  isAnotherRound: boolean = false;
  unassignedAddressCount: number;
  teamAddressCount: number;
  timeDone: string;

  constructor(public dialogRef: MatDialogRef<DeliverySummeryDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {
    teamAddressCount: number, unassignedAddressCount: number
  }) {
    this.teamAddressCount = data.teamAddressCount;
    this.unassignedAddressCount = data.unassignedAddressCount;
  }

  ngOnInit() {
    this.summeryMessage = `${this.teamAddressCount} משפחות יחגגו חג שבע בזכותכם!`;
    if (this.shouldShowAnotherRound()) {
          this.deliveryStatusMessage = `${this.unassignedAddressCount} חבילות נותרו במרכז החלוקה ללא צוותים שיחלקו אותם.`;
    }
    const currentdate = new Date();
    this.timeDone = currentdate.getHours() + ":" + currentdate.getMinutes();
    console.log('timeDone: ', this.timeDone);

  }

  shouldShowAnotherRound() : boolean{
    return this.unassignedAddressCount > 0;
  }

  anotherRound() {
    this.isAnotherRound = true;
    this.isDoneDelivery = false;
  }

  doneDelivery() {
    this.isAnotherRound = false;
    this.isDoneDelivery = true;
  }

  closeDialog() {
    this.dialogRef.close({
      isAnotherRound: this.isAnotherRound,
      timeDone: this.timeDone
    })
  }

}
