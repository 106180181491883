import {Injectable, OnInit} from '@angular/core';
import {AddressRecord} from "../../dashboard/address-record.type";
import {NgxSpinnerService} from "ngx-spinner";
import {DaoService} from "../../app-dao/dao.service";
import * as _ from "lodash";
import {BehaviorSubject, Subject} from "rxjs";
import {FileReaderService} from "../../app-reusable-components/file-selector/services/file-reader/file-reader.service";
import {ReadXlsService} from "../../app-reusable-components/file-selector/services/read-xls/read-xls.service";
import {FieldValidator} from "../../app-reusable-components/file-selector/interfaces/fieldValidator";
import {Team} from "../../dashboard/dialogs/team-dialog/interfaces/team.type";
import {DeliveryStatus} from "../../enums/enums";
import {first} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AddressesService {
  private displayedColumns: string[] = ['Name', 'Address', 'City', 'Phone', 'Details', 'Group', 'Team', 'Status', 'Other'];

  lastKnownAddresses: AddressRecord[] = [];
  addressesOnDB$: BehaviorSubject<AddressRecord[]>;
  availableAddressesGroups$: BehaviorSubject<any[]>;
  private fieldsValidators: Array<FieldValidator>;
  teams: Team[] = [];

  constructor(private spinner: NgxSpinnerService,
              private addressesDao: DaoService<AddressRecord>,
              private fileReaderService: FileReaderService,
              private readXlsService: ReadXlsService,
              private teamsDao: DaoService<Team>
  ) {
    this.addressesOnDB$ = new BehaviorSubject<AddressRecord[]>([]);
    this.availableAddressesGroups$ = new BehaviorSubject<[]>([]);
    this.initFieldsValidations();
    this.readAddressesFromServer();
  }

  initFieldsValidations() {
    // TODO: implement
    this.fieldsValidators = [];
  }

  private readAddressesFromServer() {
    console.log('retrieving addresses...');
    this.spinner.show();
    this.addressesDao.read('addresses').subscribe(data => {
      this.lastKnownAddresses = data;
      this.addressesOnDB$.next(data);
      this.availableAddressesGroups$.next(this.calculateAvailableAddressesGroups());
      this.spinner.hide();
    });
  }

  calculateAvailableAddressesGroups(addresses: AddressRecord[] = null) {
    if (!addresses) {
      addresses = this.lastKnownAddresses;
    }
    return _.sortBy(_.uniq(_.flatMap(addresses, (i) => {
      let retVal = [];
      if (!i ['team'] || i['team'] === "") {
        retVal.push(i['group']);
      }
      return retVal;
    })));
  }

  createAddressesOnDB(addressesData: AddressRecord[]) {
    if (!addressesData || addressesData.length == 0) {
      console.log('No addresses to upload');
      return;
    }
    Object.keys(addressesData).reduce((c, k) => (c[k.toLowerCase()] = addressesData[k], c), {});
    _.forEach(addressesData, (addressRecord: AddressRecord) => {
      addressRecord.delivery_status = {status: DeliveryStatus.Unknown}
    });
    this.addressesDao.create('addresses', addressesData).then(() => {
      this.readAddressesFromServer();
    });
  }

  readAddressesFromFile(file): Promise<AddressRecord[]> {
    const reader = this.fileReaderService.getFileReader(file);
    return new Promise<AddressRecord[]>((resolve) => {
      let fileAddressesData: any[] = [];
      reader.onload = () => {
        try {
          fileAddressesData = this.readXlsService.readXLS(
            reader,
            this.displayedColumns,
            null,
            this.fieldsValidators,
            null
          );

          let index = 1;
          for (let address of fileAddressesData) {
            if (!address.hasOwnProperty('id') || !address['id']) {
              address['id'] = index;
              index++;
            }
          }
          // we want to avoid camel case records on DB
          fileAddressesData = _.map(fileAddressesData,
            (record) => _.mapKeys(record, (value, key) => key.toLowerCase()));
        } catch (e) {
          console.log('error in reading file: ', e);
          throw e;
        }
        resolve(fileAddressesData);
      };
    });

  }

  async exportAddresses() {
    const flattenAddresses = await this.flattenAddresses();
    this.readXlsService.exportArrayOfArraysAsCsv(flattenAddresses, 'haluka');
  }

  private async readTeamsFromServer() {
    //TODO: same exact methode is in teams-utils use it!
    console.log('retrieving teams...');
    this.spinner.show();
    this.teams = await this.teamsDao.read('teams').pipe(first()).toPromise();
    this.spinner.hide()
  }

  private async flattenAddresses(): Promise<any> {
    let headers = ['address', 'city', 'details', 'group', 'name', 'phone', 'team', 'delivery_status',
      'Delivery Details'];
    let retVal = [];
    retVal.push(headers);
    try {
      await this.readTeamsFromServer();
    } catch (e) {
      console.log('error in reading teams: ', e);
      throw e;
    }

    _.forEach(this.lastKnownAddresses, (addressRecord: AddressRecord) => {
      let row = [];
      for (let header of headers) {
        let field = addressRecord[header];
        if (!field) {
          row.push('');
          continue;
        }
        if (header == "team") {
          const theTeamIndex = _.findIndex(this.teams, (t) => t.id === field);
          if (theTeamIndex != -1) {
            field = this.teams[theTeamIndex].driver.name + "-" + this.teams[theTeamIndex].driver.phone + "-" + this.teams[theTeamIndex].driver.email;
          }
        }
        if (typeof field == "string") {
          field = field.trim();
          field = field.toLocaleLowerCase().replace(/[,]/g, '');
          field = field.toLocaleLowerCase().replace(/["]/g, '');
          field = field.toLocaleLowerCase().replace(/[#]/g, '');
          field = field.toLocaleLowerCase().replace(/[\n\r]/g, '');
          row.push(field);
        } else {
          if (header == 'delivery_status') {
            row.push(DeliveryStatus[field.status]);
            if (field.hasOwnProperty('details')) {
              row.push(field.details);
            }
          }
          if (header == 'group') {
            row.push(addressRecord[header].toString());
          }
        }
      }
      retVal.push(row);
    });
    return retVal;
  }

  getDelivaryStats(addresses: AddressRecord[]) {
    if (!addresses || addresses.length == 0) {
      return [];
    }
    let retVal = [];
    retVal[DeliveryStatus.Unknown] = {count: 0, percentage: 0};
    retVal[DeliveryStatus.Other] = {count: 0, percentage: 0};
    retVal[DeliveryStatus.UnDelivered] = {count: 0, percentage: 0};
    retVal[DeliveryStatus.Delivered] = {count: 0, percentage: 0};

    if (addresses && addresses.length > 0) {
      _.forEach(addresses, (address: AddressRecord) => {
        if (address.hasOwnProperty('delivery_status')) {
          retVal[address.delivery_status.status]['count'] += 1;
        } else {
          retVal[DeliveryStatus.Unknown]['count'] += 1;
        }
      });
    }
    for (let i = 0; i < retVal.length; i++) {
      let retValElement = retVal[i];
      let percentage = Math.round(((retValElement['count'] / addresses.length) * 100));

      retVal[i]['percentage'] = percentage;
    }
    return retVal;
  }


}
