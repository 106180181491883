<h1>
  <p>Select Team From The List</p>
</h1>

<button (click)="confirmTeam()">Confirm Selection</button>

<p class="error" *ngIf="errorMsg">{{errorMsg}}</p>

<div mat-dialog-content>

  <p-table #dt [value]="teams" [(selection)]="selectedTeam" dataKey="id" styleClass="ui-table-customers"
           [rowHover]="true"
           [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading"
           [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
           [filterDelay]="0" [globalFilterFields]="['name','driver.name','driver.email','driver.phone']">

    <ng-template pTemplate="caption">
      List of Cars
      <div class="ui-table-globalfilter-container">
        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
               placeholder="Global Search"/>
      </div>
    </ng-template>

    <ng-template pTemplate="header">

      <tr>
        <th></th>
        <th pSortableColumn="name">Team Name
          <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th pSortableColumn="driver.name">Driver
          <p-sortIcon field="driver.name"></p-sortIcon>
        </th>
        <th pSortableColumn="driver.email">Email
          <p-sortIcon field="driver.email"></p-sortIcon>
        </th>
        <th pSortableColumn="driver.phone">Phone
          <p-sortIcon field="driver.phone"></p-sortIcon>
        </th>
        <th></th>
      </tr>
      <tr>

        <th>
          <!--<p-tableRadioButton></p-tableRadioButton>-->
        </th>
        <th>
          <input pInputText type="text" (input)="dt.filter($event.target.value, 'name', 'startsWith')"
                 placeholder="Search by Name" class="ui-column-filter">
        </th>
        <th>
          <input pInputText type="text" (input)="dt.filter($event.target.value, 'driver.name', 'contains')"
                 placeholder="Search by driver" class="ui-column-filter">
        </th>
        <th>
          <input pInputText type="text" (input)="dt.filter($event.target.value, 'driver.email', 'contains')"
                 placeholder="Search by email" class="ui-column-filter">
        </th>
        <th>
          <input pInputText type="text" (input)="dt.filter($event.target.value, 'driver.phone', 'contains')"
                 placeholder="Search by phone" class="ui-column-filter">
        </th>
        <th></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-team>
      <tr class="ui-selectable-row">
        <td>
          <p-tableRadioButton [value]="team"></p-tableRadioButton>
        </td>
        <td>
          {{team.name}}
        </td>
        <td>
          <span>{{team.driver.name}}</span>
        </td>
        <td>
          <span>{{team.driver.email}}</span>
        </td>
        <td>
          <span>{{team.driver.phone}}</span>
        </td>
        <td>
          <button pButton type="button" class="ui-button-secondary" icon="pi pi-cog"></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="8" style="text-align:left">No teams found.</td>
      </tr>
    </ng-template>
  </p-table>


</div>
