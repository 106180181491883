
export const environment = {
  production: true,
  appVersion: '3.0.3',
  firebase: {
    apiKey: "AIzaSyBgtdo_lqSbKXZD4NqvcC16ll53sKSErfo",
    authDomain: "hevretovim.firebaseapp.com",
    databaseURL: "https://hevretovim.firebaseio.com",
    projectId: "hevretovim",
    storageBucket: "",
    messagingSenderId: "564028077192"
  },
  cloudFunctionsBaseUrl: 'https://us-central1-hevretovim.cloudfunctions.net',
  loginNoPasswordUrl: 'https://hevretovim.firebaseapp.com/login'
};

// ************************* for staging *************************
// export const environment = {
//   production: true,
//   appVersion: '3.0.3',
//   firebase: {
//     apiKey: 'AIzaSyA0xyCnsnAsTnnzNuXUTeOVUr49fZefD_4',
//     authDomain: 'hevretovim-staging.firebaseapp.com',
//     databaseURL: 'https://hevretovim-staging.firebaseio.com',
//     projectId: 'hevretovim-staging',
//     storageBucket: '',
//     messagingSenderId: '214364826218'
//   },
//   cloudFunctionsBaseUrl: 'https://us-central1-hevretovim-staging.cloudfunctions.net',
//   loginNoPasswordUrl: 'https://hevretovim-staging.firebaseapp.com/login'
// };
