import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FileValidator} from "./interfaces/fileValidator";

@Component({
  selector: 'hvr-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.less']
})
export class FileSelectorComponent implements OnInit {

    @Input() selectedFilesValidators: Array<FileValidator>;
    @Input() disabled;
    @Output() onFileSelected: EventEmitter<any> = new EventEmitter();

    @ViewChild('realInput', { static: true }) realInputRef: ElementRef;

    inputPlaceHolder: string = 'No file selected';
    browseText: string = 'Browse';
    errorMessage: string = "";

    constructor() {
    }

    ngOnInit() {
        this.inputPlaceHolder = "No file selected";

        this.browseText = "Browse";
    }

    handleUploadClicked() {
        this.realInputRef.nativeElement.click();
    }

    _onFileInputChange(files: FileList): void {
        this.errorMessage = "";
        this.inputPlaceHolder = "";

        if (!files || files.length == 0) {
            console.log('no file selected');
            return;
        }

        if (this.selectedFilesValidators && this.selectedFilesValidators.length > 0) {
            for (let i = 0; i < this.selectedFilesValidators.length; i++) {
                let validator = this.selectedFilesValidators[i];
                if (!validator.validate(files)) {
                    this.errorMessage = validator.messageOnFailure ? validator.messageOnFailure : 'Bad file selected';
                    return;
                }
            }
        }

        this.inputPlaceHolder = files[0].name;
        this.onFileSelected.emit(files[0]);
    }

}
