import {Injectable} from '@angular/core';
import {AddressRecord} from "../../dashboard/address-record.type";
import {NgxSpinnerService} from "ngx-spinner";
import {DaoService} from "../../app-dao/dao.service";
import {DeliveryStatus} from "../../enums/enums";

@Injectable({
  providedIn: 'root'
})
export class AddressUtilsService {

  deliveryPossibleStatus = DeliveryStatus;

  constructor(private spinner: NgxSpinnerService, private addressesDao: DaoService<AddressRecord>) {
  }

  getAddressDisplay(address: AddressRecord): string {
    if (!address) {
      return;
    }
    return `(id:${address.id}) ${address.address}, ${address.city}`;
  }

  updateAddressOnDB(address: AddressRecord, onSuccess: Function = null, onError: Function = null) {
    this.spinner.show();
    this.addressesDao.update('addresses', [address], address.id).then(
      success => {
        console.log('address successfully updated');
        if (onSuccess) {
          onSuccess.call(success);
        }
      }
    ).catch(
      error => {
        console.log(`fail to update address: ${error}`);
        if (onError) {
          onError.call(error);
        }
      }
    ).finally(() => this.spinner.hide());
  }

  deleteAddressOnDB(addressID: string, onSuccess: Function = null, onError: Function = null) {
    this.spinner.show();
    this.addressesDao.delete('addresses', addressID).then(
      success => {
        console.log(`address ${addressID} successfully deleted`);
        if (onSuccess) {
          onSuccess.call(success);
        }
      }
    ).catch(
      error => {
        console.log(`fail to delete address ${addressID}: ${error}`);
        if (onError) {
          onError.call(error);
        }
      }
    ).finally(() => this.spinner.hide());
  }

  changeAddressDeliveryStatus(addressRecord: AddressRecord, selectedStatusText: string = null,
                              status: DeliveryStatus = DeliveryStatus.Unknown) {
    let selectedStatus = status;
    if (selectedStatusText) {
      selectedStatus = this.deliveryPossibleStatus[selectedStatusText];
    }

    switch (selectedStatus) {
      case this.deliveryPossibleStatus.Delivered: {
        addressRecord.delivery_status = {
          status: this.deliveryPossibleStatus.Delivered,
          details: null
        };
        this.updateAddressOnDB(addressRecord);
        break;
      }
      case this.deliveryPossibleStatus.UnDelivered: {
        addressRecord.delivery_status = {
          status: this.deliveryPossibleStatus.UnDelivered,
          details: null
        };
        this.updateAddressOnDB(addressRecord);
        break;
      }
      case this.deliveryPossibleStatus.Other: {
        addressRecord.delivery_status = {
          status: this.deliveryPossibleStatus.Other,
          details: null
        };
        this.updateAddressOnDB(addressRecord);
        break;
      }
      case this.deliveryPossibleStatus.Unknown: {
        addressRecord.delivery_status = {
          status: this.deliveryPossibleStatus.Unknown,
          details: null
        };
        this.updateAddressOnDB(addressRecord);
        break;
      }
    }
  }

  clearAddressTeam(address: AddressRecord, onSuccess: Function = null, onError: Function = null) {
    if (address.hasOwnProperty('team')) {
      // delete address.team;
      address.team = null;
      this.changeAddressDeliveryStatus(address, null, DeliveryStatus.Unknown);
      this.updateAddressOnDB(address, (success) => {
          if (onSuccess) {
            onSuccess.call(success);
          }
        },
        (error) => {
          console.log('failed to remove address ', error);
          if (onError) {
            onError.call(error);
          }

      });
    }
  }
}
