import {Injectable, OnInit} from '@angular/core';
import {AngularFireAuth} from 'angularfire2/auth';
import {Person} from "../../dashboard/dialogs/team-dialog/interfaces/team.type";
import {DaoService} from "../../app-dao/dao.service";
import {AppStateService} from "../state/app-state.service";
import * as firebase from "firebase";
import {environment} from "../../../environments/environment";
import {WindowService} from "../window/window.service";
import {User} from "../../types/user.type";


@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {

  constructor(private afAuth: AngularFireAuth,
              private usersDao: DaoService<Person>,
              private state: AppStateService,
              private winSrv: WindowService) {
  }

  ngOnInit(): void {
    console.log('in hvr auth');
  }

  lookForUser() {
    console.log('looking for user in session...');
    this.afAuth.auth.onAuthStateChanged(async (dbUser) => {
      await this.setCurrentUser(dbUser);
    }, (error => {
      console.log('Auth State Changed - Error: ', error);
    }));
  }

  async login(email?: string, password?: string) {
    // Existing and future Auth states are now persisted in the current
    // session only. Closing the window would clear any existing state even
    // if a user forgets to sign out.
    // ...
    // New sign-in will be persisted with session persistence.
    await this.afAuth.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    email = email.toLowerCase();
    let userCredentials = await this.afAuth.auth.signInAndRetrieveDataWithEmailAndPassword(email, password);
    if (!userCredentials.user) {
      throw new Error('Cant find user in DB, please check your credentials');
    }
    let currentUser = await this.setCurrentUser(userCredentials.user);
    if (!currentUser) {
      throw new Error('Cant find user in DB, please check your credentials');
    }
    return userCredentials.user;
  }

  async sendLoginToEmail(email: string) {

    const actionCodeSettings = {
      url: environment.loginNoPasswordUrl,
      handleCodeInApp: true
    }

    firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
      .then(function () {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email);
      })
      .catch(function (error) {
        // Some error occurred, you can inspect the code: error.code
        console.log('sign in with email link error: ', error);

      });
  }

  logout(): Promise<void> {
    return this.afAuth.auth.signOut();
  }

  async loginWithCode(confirmationResult, verificationCode) {
    await confirmationResult.confirm(verificationCode).then(async (result) => {
      // User signed in successfully.
      const user = result.user;
      if (!user) {
        throw new Error('User couldnt sign in (bad verification code?)');
      }
      console.log('user is: ', user);
      let currentUser = await this.setCurrentUser(user);
      if (!currentUser) {
        throw new Error('Cant find user in DB, verify the phone exists on a user in db');
      }
    }).catch((error) => {
      console.log('Error in loginWithCode: ', error);
      throw error;
    });
  }


  async createUser(email: string, password: string, person: Person) {
    let secondaryApp = firebase.initializeApp(environment.firebase, "Secondary"); // because by default firebase logs-in the newly created user and then we loose the session of the admin, we build the user by using a temp app to avoid this.
    try {
      if (!email && !person.email){
        console.log("Error! attempt to create user without email");
        return;
      }
      if (!person.email){
        person.email = email;
      }
      person.email = person.email.toLowerCase().trim();
      await secondaryApp.auth().createUserWithEmailAndPassword(person.email, password);
      try{
         await this.usersDao.create('users', [person]);
      }
      catch (e) {
        console.log('failed to create the driver in the DB: ', e);
      }
    } catch (error) {
      switch (error.code) {
        case 'auth/email-already-in-use':
          console.log(`Email address ${person.email} already in use.`);
          try{
            await this.usersDao.create('users', [person]);
          }
          catch (e) {
            console.log('failed to create the driver in the DB: ', e);
          }
          break;
        case 'auth/invalid-email':
          console.log(`Email address ${person.email} is invalid.`);
          break;
        case 'auth/operation-not-allowed':
          console.log(`Error during sign up.`);
          break;
        case 'auth/weak-password':
          console.log('Password is not strong enough. Add additional characters including special characters and numbers.');
          break;
        default:
          console.log(error.message);
          break;
      }
    } finally {
      secondaryApp.delete(); // we need to release the secondaryApp for the next createUser call.
    }

  }

  async setCurrentUser(user: firebase.User): Promise<User | null> {
    let theUser = null;
    if (!user) {
      this.state.setUser(null);
      return null;
    }

    if (user && user.hasOwnProperty('email')) {
      theUser = await this.usersDao.get('users', 'email', user.email);
    }

    if (!theUser && user && user.hasOwnProperty('phoneNumber')) {
      theUser = await this.usersDao.get('users', 'phone', user.phoneNumber);
    }

    if (!theUser) {
      this.state.setUser(null);
      return null;
    }

    this.state.setUser({
      email: user.email,
      dbUser: user,
      name: theUser['name'],
      phone: theUser['phone'],
      role: theUser['meta']['role']
    });
    return this.state.getUser();
  }
}

export enum AUTH_ERROR_CODES {
  emailInUse = "auth/email-already-in-use"
}
