import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {Person, Team} from "../interfaces/team.type";
import * as _ from 'lodash';

import uuidv1 from "uuid/v1";
import {UserRole} from "../../../../types/userRoles.enum";
import {AddressRecord} from "../../../address-record.type";
import {NgxSpinnerService} from "ngx-spinner";
import {DaoService} from "../../../../app-dao/dao.service";
import {ReadXlsService} from "../../../../app-reusable-components/file-selector/services/read-xls/read-xls.service";
import {DeliveryStatus} from "../../../../enums/enums";
import {first} from "rxjs/operators";
import {AddressesService} from "../../../../app-services/addresses/addresses.service";

@Injectable({
  providedIn: 'root'
})
export class TeamUtilsService implements OnInit, OnDestroy {
  lastTeamSerialNumber = 0;
  teams: Team[];
  deliveryPossibleStatus = DeliveryStatus;

  constructor(private spinner: NgxSpinnerService,
              private readXlsService: ReadXlsService,
              private teamsDao: DaoService<Team>,
              private addressesSrv: AddressesService,
              private addressesDao: DaoService<AddressRecord>) {
  }

  ngOnInit() {

  }

  ngOnDestroy() {
  }

  createTeam(): Team {
    this.lastTeamSerialNumber++;
    return {
      name: "",
      driver: this.createEmptyPerson(),
      // id: this.lastTeamSerialNumber.toString(),
      id: uuidv1(),
      members: []
    }
  }

  createEmptyPerson(): Person {
    return {
      id: uuidv1(),
      name: '',
      email: '',
      phone: '',
      meta: {role: UserRole.Member}
    };
  }

  validatePerson(person: Person, mandatoryFeilds?: string[]): Validation {
    if (mandatoryFeilds) {
      for (let field of mandatoryFeilds) {
        if (!person.hasOwnProperty(field) || !person[field].trim()) {
          return {valid: false, reason: `${field} is mandatory`};
        }
      }
    }

    if (!this.validateEmail(person.email)) {
      return {valid: false, reason: 'invalid email address'}
    }

    if (person.hasOwnProperty('phone') && !this.validatePhone(person.phone)) {
      return {valid: false, reason: 'invalid phone'}
    }
  }

  validateEmail(email: string) {
    // TODO implement
    return true;
  }

  validatePhone(phone: string) {
    // TODO implement
    return phone.length >= 10;
  }

  changePhoneToISR(phone: string) {
    if (!phone || phone === '' || phone === ' ') {
      console.log('failed changePhoneToISR - invalid phone: ', phone);
      return;
    }
    let isrPhone = phone.toString().replace('-', '');
    isrPhone = isrPhone.includes('+972') ? isrPhone :
      '+972' + (+isrPhone).toString();
    return isrPhone;
  }

  async getAddressesOfTeam(team): Promise<AddressRecord[]> {
    this.spinner.show();
    let teamAddresses = await this.addressesDao.getList('addresses', 'team', team.id);
    this.spinner.hide();
    return Promise.resolve(teamAddresses);
  }

  async exportTeams() {
    const flattenAddresses = await this.flattenTeams();
    this.readXlsService.exportArrayOfArraysAsCsv(flattenAddresses, 'teams');
  }

  private async readTeamsFromServer() {
    console.log('retrieving teams...');
    this.spinner.show();
    this.teams = await this.teamsDao.read('teams').pipe(first()).toPromise();
    this.spinner.hide()
  }

  private async flattenTeams(): Promise<any> {
    let headers = ['driver_name', 'driver_email', 'driver_phone', 'delivered', 'undelivered', 'other', 'unknown',
      'total'];
    let retVal = [];
    retVal.push(headers);
    try {
      await this.readTeamsFromServer();
    } catch (e) {
      console.log('error in reading teams: ', e);
      throw e;
    }

    const allAddresses = this.addressesSrv.addressesOnDB$.getValue();
    // const allAddresses = [];

    _.forEach(this.teams, async(team: Team) => {
      let row = [];
      let field;
      const teamAddresses = _.filter(allAddresses, (address: AddressRecord) => address.team === team.id);

      for (let header of headers) {
        switch (header) {
          case 'driver_name':
            field = team.driver.name;
            break;

          case 'driver_email':
            field = team.driver.email;
            break;

          case 'driver_phone':
            field = team.driver.phone;
            break;

          case 'delivered':
            field = _.filter(teamAddresses, (address: AddressRecord) =>
              address.delivery_status.status === this.deliveryPossibleStatus.Delivered).length.toString();
            break;
          case 'undelivered':
            field = _.filter(teamAddresses, (address: AddressRecord) =>
              address.delivery_status.status === this.deliveryPossibleStatus.UnDelivered).length.toString();
            break;
          case 'other':
            field = _.filter(teamAddresses, (address: AddressRecord) =>
              address.delivery_status.status === this.deliveryPossibleStatus.Other).length.toString();
            break;
          case 'unknown':
            field = _.filter(teamAddresses, (address: AddressRecord) =>
              address.delivery_status.status === this.deliveryPossibleStatus.Unknown).length.toString();
            break;
          case 'total':
            field = teamAddresses.length.toString();
            break;
        }

        if (typeof field == "string") {
          field = field.trim();
          field = field.toLocaleLowerCase().replace(/[,]/g, '');
          field = field.toLocaleLowerCase().replace(/["]/g, '');
          field = field.toLocaleLowerCase().replace(/[#]/g, '');
          field = field.toLocaleLowerCase().replace(/[\n\r]/g, '');

        }
        row.push(field);
      }
      retVal.push(row);
    });
    return retVal;
  }
}


export interface Validation {
  valid: boolean,
  reason?: string
}
