import {Component, OnInit} from '@angular/core';
import {Team} from "../dashboard/dialogs/team-dialog/interfaces/team.type";
import {NgxSpinnerService} from "ngx-spinner";
import {DaoService} from "../app-dao/dao.service";
import {AddressRecord} from "../dashboard/address-record.type";
import {DomSanitizer} from "@angular/platform-browser";
import * as _ from "lodash";
import {AppStateService} from "../app-services/state/app-state.service";
import {User} from "../types/user.type";
import {DeliveryStatus} from '../enums/enums';
import {TeamUtilsService} from "../dashboard/dialogs/team-dialog/services/team-utils.service";
import {HevreRouterService} from "../app-services/router/hevre-router.service";
import {take} from "rxjs/operators";
import {CloudFunctionsService} from "../cloud-functions/cloud-functions.service";
import {WindowService} from "../app-services/window/window.service";
import {TeamBulkDialogComponent} from "../dashboard/dialogs/team-bulk-dialog/team-bulk-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {DeliverySummeryDialogComponent} from "./dialogs/delivery-summery-dialog/delivery-summery-dialog.component";
import {AddressesService} from "../app-services/addresses/addresses.service";
import {ToolsService} from "../app-services/tools/tools.service";

const AddressesToTeamTextEnable: string = "הקצה לי כתובות לחלוקה";
const AddressesToTeamTextDisable: string = "אנא המתן...";

@Component({
  selector: 'hvr-team-view',
  templateUrl: './team-view.component.html',
  styleUrls: ['./team-view.component.less']
})
export class TeamViewComponent implements OnInit {
  team: Team;
  addresses: AddressRecord[] = [];
  teamMessage: string = 'No Addresses For This Team Yet.';
  currentAddressRecord: AddressRecord = null;
  addressIndex = 1;
  DeliveryStatus = DeliveryStatus;
  userInSession: User;
  totalAddressesCount = 0;
  showMap: boolean = false;
  numberOfPackages = 0;
  errorMsg;
  isSetAddressesToTeamDisabled: boolean = false;
  AddressesToTeamText: string;




  constructor(
    private spinner: NgxSpinnerService,
    private teamsDao: DaoService<Team>,
    private addressesDao: DaoService<AddressRecord>,
    private sanitizer: DomSanitizer,
    private appStateService: AppStateService,
    private teamsUtils: TeamUtilsService,
    private HevreRouterSrv: HevreRouterService,
    private cloudFunctionsSrv: CloudFunctionsService,
    private dialog: MatDialog,
    private addressesSrv: AddressesService,
    private windowSrv: WindowService,
    private toolsSrv: ToolsService
  ) {
  }

  async ngOnInit() {
    await this.getTeamFromDB();
    this.userInSession = this.appStateService.getUser();
    this.disableSetAddressesForTeam(this.isSetAddressesToTeamDisabled);
  }

  private async getTeamFromDB() {
    // TODO change implementation to requested team
    this.spinner.show();

    // if (this.userInSession && this.userInSession.hasOwnProperty('email')) {
    //   this.team = await this.teamsDao.get('teams', 'driver.email', this.userInSession.email);
    // }
    //
    // if (this.userInSession && this.userInSession.hasOwnProperty('phone')) {
    //   this.team = await this.teamsDao.get('teams', 'driver.phone', this.userInSession.phone);
    // }
    //
    // console.log('the team is: ', this.team);


    this.teamsDao.read('teams').subscribe((data) => {
      this.team = _.find(data, (obj) => obj.driver.email == this.userInSession.email); // TODO: this approach is not secure, better have a specific call to the specific team of the userInSession
      if (!this.team) {
        this.team = _.find(data, (obj) => obj.driver.phone == this.userInSession.phone);
      }
      this.spinner.hide();
      this.getAddressesFromDB();
    })
  }

  async getAddressesFromDB() {
    if (!this.team) {
      return;
    }
    this.addresses = await this.getAddressesOfTeam(this.team);
    if (this.addresses.length > 0) {
      this.clearTeamMessage();
      this.currentAddressRecord = this.addresses[0];
      this.totalAddressesCount = this.addresses.length;
    }
  }

  showOtherDetails() {
    return this.currentAddressRecord.hasOwnProperty('delivery_status') &&
      (this.currentAddressRecord.delivery_status.status == DeliveryStatus.Other ||
        this.currentAddressRecord.delivery_status.status == DeliveryStatus.Delivered ||
        this.currentAddressRecord.delivery_status.status == DeliveryStatus.UnDelivered);
  }

  async getAddressesOfTeam(team): Promise<AddressRecord[]> {
    //TODO: this exact function exists also in team-card.component needs to be centralized
    this.spinner.show();
    let teamAddresses = await this.teamsUtils.getAddressesOfTeam(team);
    this.spinner.hide();
    return Promise.resolve(teamAddresses);
  }

  hasAddresses() {
    return this.addresses.length > 0;
  }

  clearTeamMessage() {
    this.teamMessage = '';
  }

  addressRecordToString(): string {
    if (!this.currentAddressRecord) {
      return;
    }
    return this.currentAddressRecord['address'] + ' ' + this.currentAddressRecord['city'];
  }

  addressFamilyToString(): string {
    if (!this.currentAddressRecord) {
      return;
    }
    const addressDetails = this.currentAddressRecord.hasOwnProperty('details') ?
      this.currentAddressRecord['details'] : '';
    return this.currentAddressRecord['name'] + ', ' + addressDetails + ' ';
  }

  getAddressMapUrl() {
    let addressToDispaly = this.addressRecordToString();
    return this.sanitizer.bypassSecurityTrustResourceUrl(`https://maps.google.com/maps?q=${addressToDispaly}&t=&z=13&ie=UTF8&iwloc=&output=embed`);
  }

  getAddressWazeUrl() {
    let addressToDispaly = this.addressRecordToString();
    return this.sanitizer.bypassSecurityTrustResourceUrl(`https://waze.com/ul?q=${addressToDispaly}`);
  }

  isAllAddressesHasStatus(): boolean {
    if (!this.addresses || this.addresses.length === 0) {
      return false;
    }
    let retVal =  _.find(this.addresses, (address:AddressRecord) => address.delivery_status.status === DeliveryStatus.Unknown);
    return !!!retVal;
  }

  nextAddress() {
    if (!this.addresses) {
      return;
    }
    if (this.addressIndex == this.addresses.length - 1) {
      this.addressIndex = 0;
    } else {
      this.addressIndex += 1;
    }
    this.currentAddressRecord = this.addresses[this.addressIndex];
    this.showMap = false;
  }

  previousAddress() {
    if (!this.addresses) {
      return;
    }
    if (this.addressIndex == 0) {
      this.addressIndex = this.addresses.length - 1;
    } else {
      this.addressIndex -= 1;
    }
    this.currentAddressRecord = this.addresses[this.addressIndex];
    this.showMap = false;

  }

  showDoneDeliverySummery() {
    const allAddresses = this.addressesSrv.addressesOnDB$.getValue();
    let unassignedAddressesCount = _.filter(allAddresses, i => _.isNil(i.team)).length || 0;
    const dialogRef = this.dialog.open(DeliverySummeryDialogComponent, {
      width: '75%',
      data: {
        teamAddressCount: this.addresses.length,
        unassignedAddressCount: unassignedAddressesCount
      }
    });

    dialogRef.afterClosed().subscribe(dialogResults => {

      if (!dialogResults) {
        return;
      }

      console.log('dialogResults: ', dialogResults);


    })
  }

  handleDelivery(status: DeliveryStatus) {
    this.currentAddressRecord['last_update'] = this.toolsSrv.timeStampToTimeFormat(Date.now());
    this.currentAddressRecord['last_update_stamp'] = Date.now();
    switch (status) {
      case DeliveryStatus.Delivered: {
        if (this.currentAddressRecord.delivery_status &&
          this.currentAddressRecord.delivery_status.status == DeliveryStatus.Delivered) {
          this.cleanDelivaryStatus();
        } else {
          this.currentAddressRecord.delivery_status = {
            status: DeliveryStatus.Delivered,
            details: null
          };
        }
        this.currentAddressRecord['Team'] = this.team.id;
        this.addressesDao.update('addresses', [this.currentAddressRecord]);
        break;
      }
      case DeliveryStatus.UnDelivered: {
        if (this.currentAddressRecord.delivery_status &&
          this.currentAddressRecord.delivery_status.status == DeliveryStatus.UnDelivered) {
          this.cleanDelivaryStatus();
        } else {
          this.currentAddressRecord.delivery_status = {
            status: DeliveryStatus.UnDelivered,
            details: null
          };
        }
        this.currentAddressRecord['Team'] = this.team.id;
        this.addressesDao.update('addresses', [this.currentAddressRecord]);
        break;
      }
      case DeliveryStatus.Other: {
        if (this.currentAddressRecord.delivery_status &&
          this.currentAddressRecord.delivery_status.status == DeliveryStatus.Other) {
          this.cleanDelivaryStatus();
        } else {
          this.currentAddressRecord.delivery_status = {
            status: DeliveryStatus.Other,
          };
        }
        this.currentAddressRecord['team'] = this.team.id;
        this.addressesDao.update('addresses', [this.currentAddressRecord]);
        break;
      }
    }
    if (this.isAllAddressesHasStatus()) {
      this.showDoneDeliverySummery();
    }
  }

  cleanDelivaryStatus() {
    this.currentAddressRecord.delivery_status = {
      status: DeliveryStatus.Unknown,
      details: null
    };
  }

  reportOtherDelivery(event) {
    if (this.currentAddressRecord.delivery_status.status != DeliveryStatus.Other &&
      this.currentAddressRecord.delivery_status.status != DeliveryStatus.Delivered &&
      this.currentAddressRecord.delivery_status.status != DeliveryStatus.UnDelivered) {
      return;
    }
    this.addressesDao.update('addresses', [this.currentAddressRecord]);
  }

  getCurrentDeliveryStatus() {
    if (this.currentAddressRecord) {
      if (this.currentAddressRecord.hasOwnProperty('delivery_status')) {
        return DeliveryStatus[this.currentAddressRecord.delivery_status.status];
      }
    }
    return DeliveryStatus.Unknown;
  }

  getOtherInputValue() {
    if (!this.currentAddressRecord.hasOwnProperty('delivery_status')) {
      return null;
    }
    if (!this.currentAddressRecord.delivery_status.hasOwnProperty('details')) {
      return null;
    }
    return this.currentAddressRecord.delivery_status.details;
  }

  sendWhatsappMessage() {
    const initialMessage = "";
    const phone = "559209636‬‏"; // the phone of the hevreTovim Call Center
    let whatsappUrl = `https://api.whatsapp.com/send?phone=972${phone}&text=${initialMessage}&source=&data=&app_absent=`;
    this.HevreRouterSrv.navigate([whatsappUrl], true);
  }

  setAddressForTeam() {
    if(this.isSetAddressesToTeamDisabled) {
      console.log('set addresses for team is disabled')
      return;
    }

    if (!this.numberOfPackages || this.numberOfPackages === 0) {
      console.log('invalid number of packages');
      this.errorMsg = 'invalid number of packages';
      return;
    }

    this.disableSetAddressesForTeam(true);
    this.spinner.show();
    this.setTeamDeliveryStartTime();
    this.cloudFunctionsSrv.assignAddressesForTeam(this.team.id, this.numberOfPackages, null).pipe(take(1)).toPromise()
      .then((response) => {
        console.log('response is: ', response);
        this.errorMsg = null;
        this.disableSetAddressesForTeam(false);
        this.spinner.hide();
        this.windowSrv.reloadWindow();
      }).catch((error) => {
      console.log('error in set addresses for team: ', error)
      this.disableSetAddressesForTeam(false);
      this.spinner.hide();
      this.errorMsg = error.statusText;
    });

  }

  disableSetAddressesForTeam(shouldDisable: boolean) {
    this.isSetAddressesToTeamDisabled = shouldDisable;
    this.AddressesToTeamText= shouldDisable ? AddressesToTeamTextDisable : AddressesToTeamTextEnable;
  }

  setTeamDeliveryStartTime() {
    this.team.deliveryStartTimeStamp = Date.now();
    this.team.deliveryStartTime = this.toolsSrv.timeStampToTimeFormat(Date.now());
    this.teamsDao.update('teams', [this.team], this.team.id).then(
      success => {
        console.log('team successfully updated');
      }
    ).catch(
      error => {
        console.log(`fail to update team: ${error}`);
        // this.spinner.hide();
      }
    );
  }

}
