<div id="delivery-summery">
  <div id="first-messages" *ngIf="!isAnotherRound && !isDoneDelivery">
    <h1 id="dialog-header">
      תודה רבה!
    </h1>
    <p>אין ספק שאתם מהח׳ברה!</p>
    <p>{{summeryMessage}}</p>
    <p>{{deliveryStatusMessage}}</p>

    <div id="operations">
      <button mat-raised-button color="accent" style="direction: rtl" (click)="anotherRound()" *ngIf="shouldShowAnotherRound()">אני רוצה לצאת לסיבוב
        חלוקה נוסף!
      </button>
      <button mat-raised-button color="accent" style="direction: rtl" (click)="doneDelivery()">אני סיימתי, ניפגש בחג
        הבא
      </button>
    </div>
  </div>


  <div *ngIf="isDoneDelivery">
    <p>תודה רבה שהגעתם היום, ניפגש בחג הבא.</p>
    <p>אם נותרו אצלכם חבילות שלא חולקו אנא החזירו אותם למרכז החלוקה</p>
    <p>מקווים שבאתם לחזק ויצאתם מחוזקים</p>
    <p id="donation">נשמח לתרומה למימון הפעילות הבאה של העמותה, העמותה פועלת ללא מקבלי שכר ו 100% מכספי התרומה מגיעים
      למי שזקוק לכך.</p>
    <a href="https://hevretovim.org/#donation" target="_blank">
      לתרומה באתר החבר'ה
      הטובים</a>
  </div>

  <div *ngIf="isAnotherRound">
    <p>אתם מדהימים!!!</p>
    <p>סעו למרכז החלוקה בחזרה וקחו עוד חבילות</p>
    <button mat-raised-button color="accent" style="direction: rtl" (click)="closeDialog()"> אישור</button>
  </div>
</div>

