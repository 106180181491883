<div>
  <div class="frame no-print-data">
    <h2>Bulk Upload Teams From File</h2>
    <button mat-raised-button color="primary" class="no-print-data" (click)="handleBulkTeamsCreation()">
      Bulk Teams Creation</button>

    <h2>Export All Teams</h2>
    <button mat-raised-button color="primary" style="background-color: green;" class="no-print-data" (click)="exportTeams()">
      export Drivers List</button>

  <h2>Delete All Teams</h2>
    <button mat-raised-button color="primary" style="background-color: red;" class="no-print-data" (click)="deleteAllTeams()">
      Delete All Teams!</button>
  </div>

    <div class="frame no-print-data">
      <h2>Teams</h2>

      <label for="team-search">Search for team:</label>
      <input type="search" id="team-search" name="q" class="no-print-data"
             aria-label="Search for team" (keyup)="searchTeam($event)">

      <h3>Add New Team:</h3>
      <button id="add-team" mat-icon-button (click)="handleAddTeam()" matTooltip="Add New Team"
              class="hvr-button no-print-data">
        <mat-icon>add_circle</mat-icon>
      </button>
    </div>
    <div id="teams-list">
      <hvr-team-card *ngFor="let team of teams"
                     (onTeamEdited)="handleTeamEdited($event)"
                     (onTeamDeleted)="handleTeamDeleted($event)"
                     [team]="team" [availableAddressesGroups]="availableAddressesGroups">
      </hvr-team-card>
    </div>
</div>
