<div class="main">
  <header>
    <button *ngIf="user" class="pull-right" mat-raised-button color="primary" (click)="handleLogOut()">Logout</button>
    <div (click)="goHome()" class="logo-main hvr-logo-img"></div>
  </header>

  <hvr-menu *ngIf="isAdmin()"></hvr-menu>

  <div id="content">
    <router-outlet></router-outlet>
  </div>

  <footer>Version: {{appVersion}}</footer>

  <ngx-spinner
    bdColor="rgba(51, 51, 51, 0.8)"
    size="medium"
    color="#fff"
    type="ball-clip-rotate-multiple"
  ></ngx-spinner>

</div>
