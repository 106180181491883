<div class="fileSelector">
    <div class="container">
        <div class="inputContainer">
            <input id="fake-input" placeholder="{{ inputPlaceHolder }}" [disabled]="true"
                   style="position: absolute"/>
            <input #realInput id="real-input" (change)="_onFileInputChange($event.target.files)" type="file"
                   style="position: absolute"/>
        </div>
    </div>

    <div class="btn">
      <button mat-raised-button color="primary" [disabled]="disabled" (click)="handleUploadClicked()">{{ browseText }}</button>
    </div>

    <p class="error">{{ errorMessage }}</p>
</div>
