import {Component, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {BehaviorSubject, from, Observable, Subject} from "rxjs";
import {DataSource} from "@angular/cdk/table";
import * as _ from "lodash";
import {DomSanitizer} from "@angular/platform-browser";
import {AddressRecord} from "../../dashboard/address-record.type";
import {DaoService} from "../../app-dao/dao.service";
import {DeliveryStatus} from "../../enums/enums";
import {NgxSpinnerService} from "ngx-spinner";
import {Team} from "../../dashboard/dialogs/team-dialog/interfaces/team.type";
import {SelectTeamDialogComponent} from "../../manage-addresses/dialogs/select-team-dialog/select-team-dialog.component";
import {HevreRouterService} from "../../app-services/router/hevre-router.service";
import {AddressUtilsService} from "../../app-services/addresses/address-utils.service";
import has = Reflect.has;

@Component({
  selector: 'hvr-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.less']
})
export class TableComponent implements OnInit, OnChanges {
  @Input() data: any[] = [];
  @Input() filterString: string;

  @Input() displayedColumns: any[] = [];
  @Input() refreshTable: Subject<boolean>; // we need this observable in order to get notify for deep changes in the data that were made in the parent that onChanges doesn't catch
  @Output() doneLoading: Subject<boolean>; // we need this observable to notify the parent component that the table is ready for display

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  dataSource: MyDataSource;
  dataSubject = new BehaviorSubject<any[]>([]);
  dataList: any[] = [];
  activeDeliveryStatus: DeliveryStatus;
  deliveryStatusCounters: DeliveryStatusCounter[] = [];

  deliveryPossibleStatus = DeliveryStatus;
  deliveryOptions: string[] = [];

  teamsList: Team[];

  constructor(private sanitization: DomSanitizer,
              private addressesDao: DaoService<AddressRecord>,
              private addressUtilsSrv: AddressUtilsService,
              private teamsDao: DaoService<Team>,
              private dialog: MatDialog,
              private hvrRouter: HevreRouterService,
              private spinner: NgxSpinnerService) {
    this.doneLoading = new Subject<boolean>();
  }


  ngOnInit() {
    this.deliveryOptions = Object.keys(this.deliveryPossibleStatus).filter(key => !isNaN(Number(this.deliveryPossibleStatus[key])));

    this.dataSource = new MyDataSource(this.dataSubject);
    // this.dataSource.paginator = this.paginator;
    this.refreshTableData(this.data);

    this.refreshTable.subscribe({
      next: value => {
        console.log('got refresh command');
        this.refreshTableData(this.data);
      }
    });


    this.deliveryStatusCounters.push({status: this.deliveryPossibleStatus.Unknown, counter: -1});
    this.deliveryStatusCounters.push({status: this.deliveryPossibleStatus.Delivered, counter: -1});
    this.deliveryStatusCounters.push({status: this.deliveryPossibleStatus.UnDelivered, counter: -1});
    this.deliveryStatusCounters.push({status: this.deliveryPossibleStatus.Other, counter: -1});

    this.spinner.show();
    this.teamsDao.read('teams').subscribe((data) => {
      this.teamsList = data;
      this.spinner.hide();
    })
  }

  private refreshTableData(data: any[]) {
    // data = data.sort((a, b) => (Number(a.group) > Number(b.group)) ? 1 : -1); // TODO: we assum that Group is a number
    data = data.map(x => Object.assign({}, _.mapKeys(x, (value, key) =>
      key.toLowerCase()
    )));
    this.dataList = []; // we gonna rebuild dataList

    from(data).subscribe({
      next: value => {
        this.dataList.push(value);
        this.dataList = _.sortBy(this.dataList, i => i.id);
        this.dataSubject.next(this.dataList);
        if (value === data[data.length - 1]) {
          this.doneLoading.next(true);
        }
      }
    });
    if (this.activeDeliveryStatus) {
      this.applyFilterByDeliveryStatus(this.activeDeliveryStatus);
    }
    if (this.filterString) {
      this.applyFilterByAddress(this.filterString);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (Array.isArray(changes.data.currentValue)) {
      this.data = changes.data.currentValue ? changes.data.currentValue : this.data;
    }
    let changed = _.filter(this.data, 'delivery_status.status');
    for (let i = 0; i < changed.length; i++) {
      const changedElement = changed[i];
      let row = _.find(this.data, (o) => o.address == changedElement.address);
      if (row) {
        this.applyStyleOnRow(row);
      }
    }
    this.refreshTableData(this.data);
  }

  applyStyleOnRow(row) {
    if (!row) {
      return;
    }
    let style = {};
    if (row.hasOwnProperty('delivery_status')) {
      switch (row.delivery_status.status) {
        case this.deliveryPossibleStatus.Delivered: {
          style = this.sanitization.bypassSecurityTrustStyle('background-color: green;');
          break;
        }
        case this.deliveryPossibleStatus.UnDelivered: {
          style = this.sanitization.bypassSecurityTrustStyle('background-color: red;');
          break;
        }
        case this.deliveryPossibleStatus.Other: {
          style = this.sanitization.bypassSecurityTrustStyle('background-color: yellow;');
          break;
        }
      }
    }
    row.style = style;
  }

  applyFilterByAddress(filterValue: string) {
    //TODO: make it generic
    // currently filter the table by one of these: address, team, name of the reciever
    let dataListAfterFilter = this.dataList.filter((item) => {
      return item.hasOwnProperty('address') && item.address.indexOf(filterValue) != -1 ||
        item.hasOwnProperty('name') && item.name.indexOf(filterValue) != -1 ||
        item.hasOwnProperty('id') && item.id.toString() == filterValue ||
        item.hasOwnProperty('group') && item.group.toString() == filterValue;
    });

    this.dataSubject.next(dataListAfterFilter);
  }

  applyFilterByHasTeam(hasTeam: boolean | null) {
    let dataListAfterFilter = this.dataList.filter((item) => {
      if (hasTeam === null) {
        return this.dataList;
      }

      if (!hasTeam) {
        return !item.hasOwnProperty('team') || _.isNil(item.team);
      }

      return item.hasOwnProperty('team') || !_.isNil(item.team);
    });

    this.dataSubject.next(dataListAfterFilter);
  }

  applyFilterByDeliveryStatus(filterDeliveryStatusValue: DeliveryStatus) {
    this.activeDeliveryStatus = filterDeliveryStatusValue;
    let dataListAfterFilter = this.dataList.filter((item) => {
      //TODO: make it generic
      if (filterDeliveryStatusValue === null) {
        return this.dataList;
      }

      if (filterDeliveryStatusValue === this.deliveryPossibleStatus.Unknown) {
        return !item.hasOwnProperty('delivery_status') ||
          item.delivery_status.status === filterDeliveryStatusValue;
      }

      return item.hasOwnProperty('delivery_status') && item.delivery_status.status === filterDeliveryStatusValue;

    });
    this.dataSubject.next(dataListAfterFilter);
    console.log('dataListAfterFilter: ', dataListAfterFilter.length);
    let relevantCounter: DeliveryStatusCounter = this.getDeliveryCounterByDeliveryStatus(filterDeliveryStatusValue);
    if (relevantCounter) {
      relevantCounter.counter = dataListAfterFilter.length;
    }
  }

  getDeliveryCounterByDeliveryStatus(status: DeliveryStatus): DeliveryStatusCounter {
    return _.find(this.deliveryStatusCounters,
      (dsc: DeliveryStatusCounter) => dsc.status == status);
  }

  getRowDataByCol(row, col) {
    if (col.toLowerCase() === 'other') {
      return row.hasOwnProperty('delivery_status') ? row['delivery_status']['details'] : 'unknown';
    }
    if (col.toLowerCase() === 'status') {
      return row.hasOwnProperty('delivery_status') ? this.deliveryPossibleStatus[row['delivery_status']['status']] : 'unknown';
    }


    return row[col.toLowerCase()];
  }

  changeAddressDeliveryStatus(addressRecord: AddressRecord, selectedStatusText: string = null,
                              status: DeliveryStatus = DeliveryStatus.Unknown) {
    this.clearRowStyle(addressRecord);
    this.addressUtilsSrv.changeAddressDeliveryStatus(addressRecord, selectedStatusText, status);
  }

  checkForTeam(addressRecord: AddressRecord): boolean {
    return addressRecord.hasOwnProperty('team') && addressRecord.team != null;
  }

  updateAddressOnDB(address: AddressRecord, onSuccess: Function = null, onError: Function = null) {
    this.clearRowStyle(address);
    this.addressUtilsSrv.updateAddressOnDB(address, onSuccess, onError);
  }

  clearRowStyle(row) {
    if (row.hasOwnProperty('style')) {
      delete row.style;
    }
  }

  isStandardColumn(col) {
    return ['status', 'team'].indexOf(col.toLowerCase()) === -1;
  }

  seeAddressTeam(addressRecord: AddressRecord) {
    this.hvrRouter.navigate(['manage-teams'], false, {team: addressRecord.team});
  }

  clearAddressTeam(addressRecord: AddressRecord) {
    this.addressUtilsSrv.clearAddressTeam(addressRecord);
  }

  setTeamForAddress(addressRecord: AddressRecord) {
    console.log('setting team for address', addressRecord);

    const selectTeamDialogRef = this.dialog.open(SelectTeamDialogComponent, {
      width: '75%',
      data: {
        teams: this.teamsList,
      }
    });

    selectTeamDialogRef.afterClosed().subscribe(dialogResults => {

      if (!dialogResults) {
        return;
      }

      let selectedTeam = dialogResults.team;
      console.log('Team selected: ', selectedTeam);

      if (selectedTeam) {
        if (addressRecord.team === selectedTeam.id) {
          console.log('no change in the team - update will not invoked');
          return;
        }
        addressRecord.team = selectedTeam.id;
        this.updateAddressOnDB(addressRecord, (success) => this.changeAddressDeliveryStatus(addressRecord));
      }
    })
  }

}


export class MyDataSource extends DataSource<any[]> {
  paginator: MatPaginator;

  constructor(private subject: BehaviorSubject<any[]>) {
    super();
  }

  connect(): Observable<any[]> {
    return this.subject.asObservable();
  }

  disconnect(): void {
    this.subject.complete()
  }
}

export interface DeliveryStatusCounter {
  status: DeliveryStatus,
  counter: number
}

