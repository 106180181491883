import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from "@angular/router";
import {AuthService} from "../auth/auth.service";
import {Observable} from "rxjs";
import {AppStateService} from "../state/app-state.service";

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {

  constructor(private hvrAuth: AuthService, private hvrState: AppStateService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let user = this.hvrState.getUser();
    if (!user) {
      console.log('Role Guard Refuse...no user');
      return Promise.resolve(false);
    }
    let premittedRoles = route.data.hasOwnProperty('roles') ?
      route.data.roles as Array<number> : [];

    if (user.role && premittedRoles.indexOf(user.role) != -1) {
      return Promise.resolve(true);
    } else {
      console.log('Auth Guard Refuse...no role');
      return Promise.resolve(false);
    }

  }
}
