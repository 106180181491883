import {Injectable} from '@angular/core';
import {User} from "../../types/user.type";
import {State} from "../../types/state.type";
import {Subject} from "rxjs";
import {WindowService} from "../window/window.service";

@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  private state:State = {
    user: null
  };

  private stateListener: Subject<State> = new Subject<State>();

  constructor(private windowSrv: WindowService) {
    this.state = {user: null};
  }

  setUser(user:User) {
    this.state.user = user;
    this.windowSrv.getSessionStorage().setItem('user_in_session', JSON.stringify(this.state.user));
    this.handleStateChanged();
  }

  getUser(): User | null {
    return this.state.user ? this.state.user : JSON.parse(this.windowSrv.getSessionStorage().getItem('user_in_session'));
  }

  getCurrentState() {
    return this.state;
  }

  getStateListener(): Subject<State>{
    return this.stateListener;
  }

  handleStateChanged() {
    this.stateListener.next(this.getCurrentState());
  }
}
