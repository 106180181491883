import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {WindowService} from "../app-services/window/window.service";

function getAsTwelves(entities) {

  if (!entities || !entities.length) {
    return [];
  }

  let pairs = [];
  let twelves = [
    []
  ];

  for (let i = 0; i < entities.length; ++i) {

    if (i % 2 == 0) {

      if (i == entities.length - 1) {

        pairs.push({frst: entities[i], scnd: null});
      } else {
        pairs.push({frst: entities[i], scnd: entities[i + 1]});
      }
    }
  }

  for (let i = 0, j = 1; i < pairs.length; ++i) {

    if (i < (6 * j)) {
      twelves[twelves.length - 1].push(pairs[i]);

    } else {
      twelves.push([pairs[i]]);
      j++;
    }
  }

  return twelves;
}

@Component({
  selector: 'hvr-address-cards',
  templateUrl: './address-cards.component.html',
  styleUrls: ['./address-cards.component.less']
})
export class AddressCardsComponent implements OnInit {
  SCISSORS_IMG = require('../../assets/images/scissors.png');
  HEVRE_LOGO_IMG = require('../../assets/images/logo-hevre.png');
  @Input() printData;

  constructor(private windowRef: WindowService, private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.generateAddressesData();
    this.initPrintData();
  }

  initPrintData() {
    this.printData = [{teamName: String, teamDriver: String, addresses: [], rawAddresses: []}];
  }

  public generateAddressesData() {
    this.printData['addresses'] = getAsTwelves(this.printData['rawAddresses']);
    this.changeDetectorRef.detectChanges();
    this.print();
  }

  public print() {
    this.windowRef.getNativeWindow().print();
    this.initPrintData();
  }

}
